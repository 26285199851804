<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.94265 1.25H14.5241C16.0173 1.25 17.1875 2.42706 17.1875 3.82986V14.1701C17.1875 15.5737 16.0165 16.75 14.5241 16.75H3.94265C2.44946 16.75 1.2793 15.5729 1.2793 14.1701V3.82986C1.2793 2.42626 2.45028 1.25 3.94265 1.25Z"
      stroke="#dc001b"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8173 14.4422H8.16909C7.65961 14.4422 7.24463 14.0379 7.24463 13.5388C7.24463 13.0409 7.65834 12.6354 8.16909 12.6354L8.59429 12.6366V8.42042H8.16909C7.65961 8.42042 7.24463 8.01614 7.24463 7.51703C7.24463 7.01793 7.65834 6.61365 8.16909 6.61365H9.51874C10.0282 6.61365 10.4432 7.01793 10.4432 7.51703V12.6354H10.8173C11.3268 12.6354 11.7418 13.0397 11.7418 13.5388C11.7418 14.0379 11.3281 14.4422 10.8173 14.4422Z"
      fill="#dc001b"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.42273 3.01245C10.0956 3.01245 10.6409 3.54525 10.6409 4.20283C10.6409 4.8604 10.0956 5.3932 9.42273 5.3932C8.74982 5.3932 8.20459 4.8604 8.20459 4.20283C8.20587 3.54525 8.75109 3.01245 9.42273 3.01245Z"
      fill="#dc001b"
    />
  </svg>
</template>
