<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M1 1h18v18H1z" />
      <path
        fill="#E14949"
        d="M11.74 3.42l6.568 11.591a2 2 0 0 1-1.74 2.986H3.432a2 2 0 0 1-1.74-2.986L8.26 3.421a2 2 0 0 1 3.48 0z"
      />
      <g fill="#FFF">
        <path d="M9 7h2v4.706H9zM9 13.176h2v1.765H9z" />
      </g>
    </g>
  </svg>
</template>
