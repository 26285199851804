<template>
  <dialog
    :class="classList"
    @keydown.tab="tabHandler"
    @click="outsideClickHandler"
    ref="modal"
  >
    <st-card
      :is-loading="isLoading"
      :spinner-aria-label="spinnerAriaLabel"
      :loading-message="loadingMessage"
      :hide-overflow="hideOverflow"
    >
      <slot />
    </st-card>
  </dialog>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import StCard from "../card/Card.vue";
export default {
  name: "st-modal",
  components: {
    StCard,
  },
  props: {
    onClose: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
    loadingMessage: {
      type: String,
    },
    disableOutsideClick: {
      type: Boolean,
    },
    spinnerAriaLabel: {
      type: String,
      default: "",
    },
    hideOverflow: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      validator(value) {
        return ["small", "medium"].includes(value);
      },
    },
  },
  setup(props) {
    const modal = ref(null);
    const firstFocusableElement = ref(null);
    const lastFocusableElement = ref(null);

    onMounted(() => {
      const focusable = modal.value.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      firstFocusableElement.value = focusable[0];
      lastFocusableElement.value = focusable[focusable.length - 1];
    });

    const tabHandler = (e) => {
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement.value) {
          e.preventDefault();
          lastFocusableElement.value.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement.value) {
          e.preventDefault();
          firstFocusableElement.value.focus();
        }
      }
    };

    const outsideClickHandler = (e) => {
      if (modal.value && !props.disableOutsideClick) {
        const modalDimensions = modal.value.getBoundingClientRect();
        if (
          e.target.tagName === "DIALOG" &&
          (e.clientX < modalDimensions.left ||
            e.clientX > modalDimensions.right ||
            e.clientY < modalDimensions.top ||
            e.clientY > modalDimensions.bottom)
        ) {
          props.onClose ? props.onClose() : modal.value.close();
        }
      }
    };

    const classList = computed(() => {
      return ["st-modal", props.size && `st-modal--${props.size}`];
    });

    return {
      modal,
      tabHandler,
      outsideClickHandler,
      classList,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
dialog {
  box-shadow: 0 rem-calc(16) rem-calc(24) 0 rgba(43, 36, 29, 0.08);
  padding: 0;
  &::backdrop {
    background-color: rgba(249, 248, 245, 0.95);
  }
}

.st-modal {
  border: none;
  background: transparent;

  &--small {
    width: 100%;
    max-width: rem-calc(440);
  }

  &--medium {
    width: 100%;
    max-width: rem-calc(560);
  }
}
</style>
