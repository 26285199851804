<template>
  <div class="st-select">
    <label class="st-select__label" v-if="labelName" :for="id">
      {{ labelName }}
    </label>
    <select
      :id="id"
      :class="classList"
      :value="modelValue"
      :tabindex="tabindex"
      @blur="onBlur"
      @input="$emit('update:modelValue', $event.target.value)"
      :disabled="disabled"
      :required="required"
      :aria-invalid="invalid"
      :aria-required="required"
    >
      <option v-if="hasEmpty" value>
        {{ emptyOptionText }}
      </option>
      <option v-for="item in items" :key="item.id" :value="item.id">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>
<script>
import { computed } from "vue";
export default {
  name: "st-select",
  props: {
    modelValue: {
      type: [String, Number],
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: "0",
    },
    hasEmpty: {
      type: Boolean,
      default: true,
    },
    blur: {
      type: Function,
    },
    id: {
      type: String,
    },
    required: Boolean,
    disabled: Boolean,
    emptyOptionText: String,
    labelName: String,
  },
  setup(props) {
    const classList = computed(() => {
      const select = "st-select__container";
      return [
        select,
        props.invalid && `${select}--is-invalid`,
        props.disabled && `${select}--is-disabled`,
      ];
    });

    const onBlur = () => {
      if (props.blur) props.blur();
    };

    return {
      classList,
      onBlur,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-select {
  display: flex;
  flex-direction: column;

  &__label {
    color: var(--text-color-primary);
    font-family: var(--font-primary);
    padding-left: var(--xs);
    margin-bottom: rem-calc(2);
    font-size: rem-calc(14);
    font-weight: 600;
    line-height: 1.4;
    text-align: left;
  }

  &__container {
    height: rem-calc(48);
    font-family: var(--font-primary);
    border: var(--border-width--thin) solid var(--border-color-quaternary);
    border-radius: var(--border-radius);
    font-size: rem-calc(16);
    line-height: 1.5;
    cursor: pointer;
    color: var(--text-color-primary);
    width: 100%;
    padding-left: var(--lg);
    transition: 0.2s linear border-color;

    /* SVG background image */
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M1.99987 5.99997C4.99994 8.49991 7.99994 10.9999 7.99994 10.9999L14 5.99997' stroke='%233e3b39' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-color: var(--background-color-secondary);
    background-repeat: no-repeat;
    background-position: 98%;
    background-size: rem-calc(16) rem-calc(16);

    &:focus {
      box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
        0 0 0 rem-calc(4) var(--border-color-quaternary);
      outline: none;
    }

    &--is-invalid {
      border-color: var(--danger-color-primary);
      border-width: var(--border-width);
    }

    &--is-disabled {
      background-color: var(--container-color-secondary);
    }

    &::placeholder {
      color: var(--disabled-color-primary);
    }

    &::-ms-expand {
      display: none; /* remove default arrow on ie10 and ie11 */
    }
  }
}
</style>
