<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <g fill="none" fill-rule="evenodd">
      <circle cx="11.5" cy="11.5" r="11" fill="#FFF" stroke="#D6D3D0" />
      <path d="M1 .5h21v21H1z" />
      <path
        stroke="#4A4A4A"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M7.394 10.2l4.181 4.181 4.181-4.18"
      />
    </g>
  </svg>
</template>
