<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="26"
    viewBox="0 0 20 26"
  >
    <g fill="none" fill-rule="evenodd" transform="translate(.611)">
      <ellipse
        cx="9.389"
        cy="24.411"
        fill="#514F4F"
        opacity=".2"
        rx="6.572"
        ry="1"
      />
      <circle
        cx="9.389"
        cy="9.389"
        r="2.317"
        fill="#EB6666"
        fill-opacity=".8"
        stroke="#514F4F"
      />
      <path
        stroke="#514F4F"
        stroke-width="2"
        d="M9.385 23.241c.27-.173.565-.376.881-.606a21.562 21.562 0 0 0 3.084-2.74c2.776-2.991 4.428-6.497 4.428-10.506C17.778 4.786 14.005 1 9.388 1 4.774 1 1 4.786 1 9.389c0 4.046 1.652 7.559 4.426 10.537a21.163 21.163 0 0 0 3.081 2.718c.315.227.61.426.878.597z"
      />
    </g>
  </svg>
</template>
