<template>
  <svg
    width="30"
    height="26"
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.333344 17.3332C0.333344 14.2286 1.96573 11.5053 4.41902 9.97477C5.08626 4.72517 9.56916 0.666504 15 0.666504C20.4308 0.666504 24.9137 4.72517 25.5809 9.97477C28.0343 11.5053 29.6667 14.2286 29.6667 17.3332C29.6667 21.8953 26.1416 25.6341 21.6667 25.9746L8.33334 25.9998C3.85838 25.6341 0.333344 21.8953 0.333344 17.3332ZM21.4644 23.3156C24.5756 23.0789 27 20.4746 27 17.3332C27 15.2358 25.9179 13.3281 24.1695 12.2373L23.0952 11.567L22.9356 10.311C22.4313 6.34388 19.0384 3.33317 15 3.33317C10.9616 3.33317 7.56864 6.34388 7.06441 10.311L6.90476 11.567L5.83052 12.2373C4.08209 13.3281 3.00001 15.2358 3.00001 17.3332C3.00001 20.4746 5.42445 23.0789 8.53561 23.3156L8.76668 23.3332H21.2333L21.4644 23.3156ZM16.3333 15.3332V20.6665H13.6667V15.3332H9.66668L15 8.6665L20.3333 15.3332H16.3333Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6D202B",
    },
  },
};
</script>
