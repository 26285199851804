<template>
  <div :class="classList">
    <component :is="headlineTag" :class="textClassList">
      <slot>default headline</slot>
    </component>
  </div>
</template>

<script>
export default {
  name: "st-headline",
  props: {
    level: {
      type: Number,
      default: 1,
      validator(val) {
        return val > 0 && val < 7;
      },
    },
    displayBorder: Boolean,
    thin: Boolean,
  },
  computed: {
    headlineTag() {
      const headlineTagMap = {
        1: "h1",
        2: "h2",
        3: "h3",
        4: "h4",
        5: "h5",
        6: "h6",
      };
      return this.level ? headlineTagMap[this.level] : "h1";
    },
    classList() {
      const headline = "st-headline";
      return [headline, this.displayBorder && `${headline}--with-border`];
    },
    textClassList() {
      const text = "st-headline__text";
      return [
        text,
        `${text}--${this.headlineTag}`,
        this.thin && `${text}--thin`,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-headline {
  &--with-border {
    padding-bottom: rem-calc(24);
    border-bottom: rem-calc(1) solid var(--border-color-tertiary);
  }
  &__text {
    margin: 0;
    font-family: var(--font-secondary);
    color: var(--text-color-primary);
    font-weight: 700;
    line-height: 130%;
    &--h1 {
      font-size: rem-calc(34);
      @include medium {
        font-size: rem-calc(48);
      }
    }
    &--h2 {
      font-size: rem-calc(24);
      @include medium {
        font-size: rem-calc(40);
      }
    }
    &--h3 {
      font-size: rem-calc(20);
      @include medium {
        font-size: rem-calc(36);
      }
    }
    &--h4 {
      font-size: rem-calc(18);
      @include medium {
        font-size: rem-calc(24);
      }
    }
    &--h5 {
      font-size: rem-calc(16);
      @include medium {
        font-size: rem-calc(21);
      }
    }
    &--h6 {
      font-size: rem-calc(14);
      @include medium {
        font-size: rem-calc(18);
      }
    }
    &--thin {
      font-weight: 400;
    }
  }
}
</style>
