<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h14v14H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M1.75 10.063v2.187h2.188l6.451-6.452-2.187-2.187-6.452 6.451zm10.33-5.956a.58.58 0 0 0 0-.823L10.717 1.92a.58.58 0 0 0-.823 0L8.826 2.987l2.187 2.187 1.068-1.067z"
      />
    </g>
  </svg>
</template>
