<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H16V11.9981H0V0Z" fill="#0153CD" />
    <path d="M5.1416 0H6.85633V11.9981H5.1416V0Z" fill="#FDCE1B" />
    <path d="M0 5.14172H16V6.85646H0V5.14172Z" fill="#FDCE1B" />
  </svg>
</template>
