<template>
  <div class="st-table">
    <div class="st-table__mobile-sort">
      <st-table-mobile-sort
        v-if="hasSortableHeaders"
        :headers="headers"
        @sort="handleSortClick"
      />
    </div>
    <table class="st-table__content">
      <thead class="st-table__content__header">
        <tr class="st-table__content__header__row">
          <st-table-header
            v-for="(header, i) in headers"
            :key="`${header.name}-${i}`"
            :header="header"
            :is-sorted="sortBy === header.sortBy"
            @sort="handleSortClick"
          />
        </tr>
      </thead>

      <tbody class="st-table__content__body">
        <template
          v-for="(data, index) in tableData"
          :key="`table-row-${index}`"
        >
          <tr
            :class="classListRow(data, index)"
            @click="handleRowClick(data, index)"
            @keydown.enter="handleRowClick(data, index)"
            :tabindex="clickable ? '0' : '-1'"
          >
            <st-table-item
              v-for="(d, i) in data.items"
              :key="`${d.value}-${i}`"
              :data="d"
              :header="headers[i]"
              @on-item-click="$emit('on-item-click', index)"
            />
            <td
              v-if="data.expandableComponent"
              class="st-table-item__data-cell"
            >
              <div
                :class="classListChevron(isExpanded(data.id), data)"
                role="button"
                tabindex="0"
                :aria-expanded="isExpanded(data.id)"
                aria-label="För mer information, fäll ut rad nedanför"
                @keydown.enter.stop="expandRow(data.id)"
                :aria-controls="`expandable-item-${data.id}`"
              >
                <st-icon name="st-chevron" />
              </div>
            </td>
          </tr>
          <tr v-if="isExpanded(data.id)" :id="`expandable-item-${data.id}`">
            <td :colspan="headers.length">
              <component
                :is="data.expandableComponent"
                v-bind="data.expandableComponentProps"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import StTableItem from "./TableItem.vue";
import StTableHeader from "./TableHeader.vue";
import StTableMobileSort from "./TableMobileSort.vue";
import StIcon from "@/st-components/icon/Icon.vue";
import { computed, ref } from "vue";
export default {
  name: "st-table",
  components: {
    StTableItem,
    StTableHeader,
    StTableMobileSort,
    StIcon,
  },
  props: {
    tableData: {
      type: Array,
      validator: (items) => {
        const validItems = items.filter((item) => {
          if (!item.expandableRow) return true;
          return Object.prototype.hasOwnProperty.call(item, "id");
        });
        return validItems.length === items.length;
      },
    },
    headers: {
      type: Array,
    },
    clickable: {
      type: Boolean,
    },
    expandableRows: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const isSelectedRow = ref("");
    const sortBy = ref("");

    const classListRow = (currentRow, index) => {
      const row = "st-table__content__body__row";
      return [
        row,
        props.clickable && `${row}__clickable`,
        currentRow.state === "danger" && `${row}--is-danger`,
        index % 2 ? `${row}--white` : `${row}--beige`,
      ];
    };

    const classListChevron = (id) => {
      const chevron = `st-table__content__body__row__chevron`;
      return [chevron, `${chevron}--expanded-${id}`];
    };

    const isExpanded = (id) => {
      return id === isSelectedRow.value;
    };

    const expandRow = (id) => {
      isSelectedRow.value = isSelectedRow.value === id ? "" : id;
    };

    const handleSortClick = (sortingBy, order) => {
      sortBy.value = sortingBy;
      emit("sort", sortingBy, order);
    };

    const handleRowClick = (row, index) => {
      if (props.expandableRows && row.expandableComponent) {
        expandRow(row.id);
      } else if (props.clickable) {
        emit("on-row-click", row, index);
      }
    };

    return {
      hasSortableHeaders: computed(() =>
        props.headers.find((header) => header.sortBy)
      ),
      classListRow,
      handleSortClick,
      handleRowClick,
      expandRow,
      isExpanded,
      sortBy,
      isSelectedRow,
      classListChevron,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-table {
  font-family: var(--font-primary);
  font-size: rem-calc(16);
  color: var(--text-color-primary);
  @include large {
    border-right: rem-calc(1) solid var(--border-color-quinary);
    border-left: rem-calc(1) solid var(--border-color-quinary);
  }

  &__mobile-sort {
    padding: rem-calc(10);
    display: block;
    @include large {
      display: none;
    }
  }

  &__content {
    width: 100%;
    @include large {
      border-collapse: separate;
      border-spacing: 0 rem-calc(2);
      background-color: var(--border-color-quinary);
    }
    &__header {
      background-color: var(--container-color-tertiary);
      display: none;
      @include large {
        display: table-header-group;
      }
      &__row {
        border: solid var(--border-color-quinary) rem-calc(1);
        vertical-align: baseline;
      }
    }
    &__body {
      &__row {
        background-color: var(--container-color-primary);
        display: flex;
        flex-direction: column;
        transition: 200ms linear background;
        border-color: var(--border-color-quinary) var(--border-color-quinary)
          var(--border-color-quinary) var(--border-color-primary);
        border-width: rem-calc(1) rem-calc(1) rem-calc(1) rem-calc(3);
        border-style: solid;
        margin-top: rem-calc(5);
        &:last-child {
          border-bottom-width: rem-calc(2);
        }

        &__chevron {
          display: flex;
          justify-content: center;
          padding: rem-calc(2) 0 rem-calc(20) 0;
          &:focus {
            outline: rem-calc(2) solid var(--border-color-quaternary);
          }

          @include large {
            padding: 0 rem-calc(12) 0 0;
            &--expanded-true {
              padding-top: 0 !important;
            }
          }
          &--expanded-true {
            transform: scaleY(-1);
            padding-bottom: 0;
            padding-top: rem-calc(12);
          }
        }

        @include large {
          display: table-row;
        }
        &:focus {
          outline: rem-calc(2) solid var(--border-color-quaternary);
        }
        &--white {
          background-color: var(--container-color-secondary);
        }
        &--beige {
          background-color: var(--container-color-primary);
        }
        &__clickable {
          cursor: pointer;
          &:hover {
            background-color: var(--background-color-quaternary);
          }
        }
      }
      .st-table__content__body {
        &__row--is-danger {
          background-color: var(--danger-color-secondary);
          border-top: rem-calc(1) solid var(--danger-color-primary);
          border-bottom: rem-calc(1) solid var(--danger-color-primary);
          border-right: rem-calc(1) solid var(--danger-color-primary);

          @include large {
            border: none;
            & .st-table-item__data-cell {
              border-top: rem-calc(1) solid var(--danger-color-primary);
              border-bottom: rem-calc(1) solid var(--danger-color-primary);
              &:last-child {
                border-right: rem-calc(1) solid var(--danger-color-primary);
              }
            }
          }
        }
        &__row {
          &__clickable {
            cursor: pointer;
            &:hover {
              background-color: var(--background-color-quaternary);
            }
          }
        }
      }
    }
  }
}
</style>
