<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h14v14H0z" />
      <rect
        width="2"
        height="11"
        x="6"
        y="2"
        fill="#6E6054"
        rx="1"
        transform="rotate(45 7 7.5)"
      />
      <rect
        width="2"
        height="11"
        x="6"
        y="2"
        fill="#6E6054"
        rx="1"
        transform="scale(-1 1) rotate(45 0 -9.4)"
      />
    </g>
  </svg>
</template>
