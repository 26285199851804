<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9999 53.6666C12.2723 53.6666 0.333252 41.7274 0.333252 27C0.333252 12.2724 12.2723 0.333313 26.9999 0.333313C41.7274 0.333313 53.6666 12.2724 53.6666 27C53.6666 41.7274 41.7274 53.6666 26.9999 53.6666ZM24.3333 35V40.3333H29.6666V35H24.3333ZM24.3333 13.6666V29.6666H29.6666V13.6666H24.3333Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#CC4039",
    },
  },
};
</script>
