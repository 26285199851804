<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Varning</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(1.000000, 2.000000)" fill="#E14949">
        <path
          d="M9.95599041,0.0978331343 C10.2515245,0.269876396 10.4980096,0.51482282 10.6719002,0.809273717 L17.8612685,12.9831123 C18.4229485,13.9342114 18.107262,15.160561 17.1561629,15.722241 C16.8481142,15.9041619 16.4969072,16.0001239 16.1391514,16.0001239 L1.86286092,16.0001239 C0.758291419,16.0001239 -0.137139082,15.1046934 -0.137139082,14.0001239 C-0.137139082,13.6466171 -0.0434417652,13.2994263 0.134408781,12.9939165 L7.22133094,0.820077931 C7.77704391,-0.134519825 9.00139266,-0.457879835 9.95599041,0.0978331343 Z M9.01367795,1.25328815 L1.65492244,14.3614765 L16.3724335,14.3614765 L9.01367795,1.25328815 Z M9.83131745,11.0844294 L9.83131745,12.7229529 L8.19603845,12.7229529 L8.19603845,11.0844294 L9.83131745,11.0844294 Z M9.83131745,5.349597 L9.83131745,9.44590584 L8.19603845,9.44590584 L8.19603845,5.349597 L9.83131745,5.349597 Z"
        ></path>
      </g>
    </g>
  </svg>
</template>
