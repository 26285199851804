<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>En bro</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <rect x="0" y="0" width="20" height="20"></rect>
        <polygon fill="#514F4F" points="2 2 4 2 4 16 2 16"></polygon>
        <polygon
          fill="#514F4F"
          transform="translate(17.000000, 9.000000) scale(-1, 1) translate(-17.000000, -9.000000) "
          points="16 2 18 2 18 16 16 16"
        ></polygon>
        <path
          d="M3,4 L10.8292893,11.8292893 C10.8683418,11.8683418 10.8683418,11.9316582 10.8292893,11.9707107 C10.8105357,11.9894643 10.7851003,12 10.7585786,12 L3.1,12 C3.04477153,12 3,11.9552285 3,11.9"
          stroke="#514F4F"
        ></path>
        <polyline stroke="#514F4F" points="3 7 8 12 2 12"></polyline>
        <path
          d="M17,4 L17,11.9 C17,11.9552285 16.9552285,12 16.9,12 L9.24142136,12 C9.18619288,12 9.14142136,11.9552285 9.14142136,11.9 C9.14142136,11.8734784 9.15195704,11.848043 9.17071068,11.8292893 L17,4 L17,4 Z"
          stroke="#514F4F"
        ></path>
        <polygon stroke="#514F4F" points="17 7 17 12 12 12"></polygon>
        <path
          d="M1.59716038,18.7916589 L2.40283962,17.5263997 C2.5216606,17.6020613 2.78358587,17.7337955 3.1702819,17.8707271 C3.83268071,18.1052869 4.5865721,18.246611 5.41630783,18.2463923 C6.13243232,18.2462035 6.54555974,18.1610346 7.28630708,17.9120971 C7.35461124,17.8891003 7.35461124,17.8891003 7.42451766,17.8655449 C8.43321037,17.526142 9.09836683,17.3958446 10.2349594,17.406896 C11.3091741,17.417341 11.9773345,17.5553863 12.8651751,17.8716779 C13.0021526,17.9207864 13.0818037,17.9494079 13.1310269,17.9668713 C13.7688758,18.193168 14.1642158,18.269133 14.8463287,18.2467941 C15.6108487,18.2217564 16.2747488,18.0622145 16.8374726,17.8139152 C17.1918154,17.6575631 17.4193923,17.5094834 17.5132209,17.4294335 L18.4867791,18.5705665 C18.2875257,18.7405596 17.9386829,18.9675445 17.4430121,19.186257 C16.7097338,19.5098126 15.8581272,19.7144623 14.8954268,19.7459903 C14.0049617,19.7751527 13.4307799,19.664823 12.6294847,19.3805387 C12.5767113,19.3618157 12.4933076,19.3318457 12.3617917,19.2846908 C11.6202935,19.0205333 11.1118851,18.9154936 10.2203751,18.9068251 C9.27279431,18.8976115 8.76539562,18.9970058 7.90288094,19.2872229 C7.83411379,19.3103947 7.83411379,19.3103947 7.76413989,19.3339536 C6.88263118,19.6301957 6.32015372,19.7461541 5.41670321,19.7463922 C4.40787624,19.7466581 3.48631016,19.5739019 2.66958661,19.2846947 C2.16951247,19.1076151 1.80709354,18.9253381 1.59716038,18.7916589 Z"
          fill="#514F4F"
          fill-rule="nonzero"
        ></path>
      </g>
      <rect fill="#514F4F" x="0" y="11" width="20" height="2"></rect>
    </g>
  </svg>
</template>
