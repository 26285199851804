<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="8" width="22" height="2" rx="1" :fill="color" />
    <rect x="4" y="14" width="22" height="2" rx="1" :fill="color" />
    <rect x="4" y="20" width="22" height="2" rx="1" :fill="color" />
  </svg>
</template>
<script>
export default {
  name: "st-hamburger",
  props: {
    color: {
      type: String,
      default: "#514F4F",
    },
  },
};
</script>
