<template>
  <div class="st-occupancy" :class="{ 'st-occupancy--is-boxed': showText }">
    <div class="st-occupancy__container" aria-hidden="true">
      <template v-if="percentage !== null">
        <div
          v-for="index in 10"
          :key="index"
          class="st-occupancy__container__icons"
        >
          <occupancy-human-icon
            :color="index <= percentageIcons ? '#514F4F' : '#D6D3D0'"
            :rotation="index % 2 ? '' : 'right'"
          ></occupancy-human-icon>
        </div>
      </template>
      <div v-else-if="percentage === null && !showText">
        <p class="st-occupancy__container__no-svg">Prognos saknas</p>
      </div>
    </div>
    <p
      v-if="showText"
      class="st-occupancy__paragraph"
      :class="{
        'st-occupancy__paragraph--with-margin-left': percentage !== null,
      }"
    >
      {{
        percentage
          ? percentage + `% fullt enligt prognos`
          : `Ingen prognos för hur många som reser`
      }}
    </p>
  </div>
</template>

<script>
import OccupancyHumanIcon from "./OccupancyHumanIcon.vue";
export default {
  name: "OccupancyContainer",
  props: { percentage: Number, showText: Boolean },
  components: { OccupancyHumanIcon },
  setup(props) {
    const percentageIcons = props.percentage * 10 * 0.01;
    return { percentageIcons };
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/main.scss";
@mixin three20 {
  @media (max-width: 320px) {
    @content;
  }
}
.st-occupancy {
  display: inline-flex;
  @include three20 {
    flex-direction: column;
  }
  align-items: center;
  padding: rem-calc(4) rem-calc(12) rem-calc(4) rem-calc(0);
  &__container {
    display: flex;
    &__icons {
      display: flex;
      &:nth-child(even) {
        margin-right: rem-calc(1);
      }
    }
    &__no-svg {
      margin: 0;
    }
  }
  &__paragraph {
    margin: 0;
    &--with-margin-left {
      margin-left: rem-calc(8);
    }
  }
  &--is-boxed {
    margin-top: rem-calc(7);
    padding: rem-calc(4) rem-calc(12) rem-calc(4) rem-calc(12);
    color: var(--text-color-primary);
    background-color: var(--background-color-tertiary);
    border-radius: rem-calc(4);
  }
}
</style>
