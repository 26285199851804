<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99987 5.99997C4.99994 8.49991 7.99994 10.9999 7.99994 10.9999L14 5.99997"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: "st-chevron",
  props: {
    color: {
      type: String,
      default: "#3e3b39",
    },
  },
};
</script>
