<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h56v56H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M28 4.667C15.12 4.667 4.667 15.12 4.667 28 4.667 40.88 15.12 51.333 28 51.333c12.88 0 23.333-10.453 23.333-23.333C51.333 15.12 40.88 4.667 28 4.667zm0 7c3.873 0 7 3.126 7 7 0 3.873-3.127 7-7 7a6.99 6.99 0 0 1-7-7c0-3.874 3.127-7 7-7zM28 44.8c-5.833 0-10.99-2.987-14-7.513.07-4.644 9.333-7.187 14-7.187 4.643 0 13.93 2.543 14 7.187-3.01 4.526-8.167 7.513-14 7.513z"
      />
    </g>
  </svg>
</template>
