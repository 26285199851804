<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#514F4F"
        d="M.8 1.994C.8.893 1.692 0 2.808 0h18.384c1.11 0 2.008.895 2.008 1.994v12.012A1.996 1.996 0 0 1 21.192 16H2.808A2.002 2.002 0 0 1 .8 14.006V1.994zm1.6.108v11.796c0 .28.221.502.494.502h18.212a.494.494 0 0 0 .494-.502V2.102a.496.496 0 0 0-.494-.502H2.894a.494.494 0 0 0-.494.502z"
      />
      <path fill="#514F4F" d="M11.2 8.8H20v1h-8.8zM11.2 7.2h7.2v1h-7.2z" />
      <path fill="#6CBF0F" d="M16.8 3.2H20v3.2h-3.2z" opacity=".8" />
      <path
        fill="#514F4F"
        d="M11.2 10.4h7.2v1h-7.2zM8.8 8.8H5.6V12H4V4h1.6v3.2h3.2V4h1.6v8H8.8V8.8z"
      />
      <g>
        <path stroke="#514F4F" d="M9.3 14.9h5.4v3.8H9.3z" />
        <path fill="#514F4F" d="M9.6 16h4.8v1.6H9.6z" opacity=".2" />
        <path fill="#514F4F" d="M11.2 18.4h1.6V24h-1.6z" />
      </g>
    </g>
  </svg>
</template>
