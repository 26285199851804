<template>
  <div class="st-spinner" aria-live="assertive" :aria-atomic="true">
    <component class="st-spinner__loader" :is="spinnerVariation" />
    <span ref="spinnerMessage" :class="messageClassList"></span>
  </div>
</template>

<script>
import SmallSpinner from "./SmallSpinner.vue";
import LargeSpinner from "./LargeSpinner.vue";
import { computed, onMounted, ref } from "vue";
export default {
  name: "st-spinner",
  props: {
    loadingMessage: {
      type: String,
      default: "Laddar...",
      required: true,
    },
    small: {
      type: Boolean,
    },
    hideLoadingMessage: {
      type: Boolean,
    },
    font: {
      type: String,
      validator(font) {
        return ["primary"].includes(font);
      },
    },
  },
  setup(props) {
    const spinnerMessage = ref(null);
    onMounted(() => {
      setTimeout(() => {
        if (spinnerMessage.value) {
          spinnerMessage.value.innerHTML = props.loadingMessage;
        }
      }, 100);
    });

    const messageClassList = computed(() => {
      const message = "st-spinner__message";
      return [
        message,
        (props.hideLoadingMessage || props.small) && `${message}--sr-only`,
        props.font && `${message}--font-${props.font}`,
      ];
    });

    const spinnerVariation = computed(() => {
      return props.small ? SmallSpinner : LargeSpinner;
    });

    return {
      messageClassList,
      spinnerVariation,
      spinnerMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-secondary);

  &__loader {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotateZ(0);
    }
    to {
      transform: rotateZ(360deg);
    }
  }

  &__message {
    margin-top: rem-calc(16);
    font-size: rem-calc(24);
    font-weight: 700;
    color: var(--text-color-primary);
    line-height: rem-calc(32);

    &--font-primary {
      font-family: var(--font-primary);
      font-weight: 400;
    }

    &--sr-only {
      @include sr-only();
    }
  }
}
</style>
