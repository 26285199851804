<template>
  <div class="st-number-notification">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" :fill="color" />
    </svg>

    <span class="st-number-notification__number" :aria-hidden="true">
      {{ notificationText }}
    </span>
  </div>
</template>
<script>
export default {
  name: "st-number-notification",
  props: {
    color: {
      type: String,
      default: "#cc4039",
    },
    number: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    notificationText() {
      return this.number < 100 ? this.number : "99+";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-number-notification {
  display: inline-flex;
  position: relative;
  font-family: var(--font-primary);
  &__number {
    color: var(--text-color-tertiary);
    font-size: rem-calc(12);
    font-weight: 600;
    display: flex;
    align-items: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
