<template>
  <li
    class="st-list-item"
    :class="{
      'st-list-item--no-border-bottom': skipBorderBottom,
      'st-list-item--is-vertical': isVertical,
    }"
    v-if="hasOtherValueThanNull"
  >
    <span class="st-list-item__title">{{ item.label }}</span>
    <ul
      class="st-list-item__values"
      :class="{ 'st-list-item__values--is-vertical': isVertical }"
    >
      <li
        class="st-list-item__values__value st-list-item__values__value--is-semi-bold"
        v-if="typeof item.value === 'string'"
      >
        {{ item.value }}
      </li>
      <li
        class="st-list-item__values__value"
        :class="valueSemiBoldClass(index)"
        v-for="(value, index) in item.value"
        :key="`${value}-${index}`"
        v-else
      >
        {{ value }}
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "st-list-item",
  props: {
    item: Object,
    skipBorderBottom: Boolean,
    isVertical: Boolean,
  },
  setup(props) {
    return {
      hasOtherValueThanNull: Array.isArray(props.item.value)
        ? props.item.value.some((val) => val !== null)
        : props.item.value !== null,
      valueSemiBoldClass: (index) =>
        props.item.semiboldText || index % 2 === 0
          ? "st-list-item__values__value--is-semi-bold"
          : "",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-list-item {
  display: flex;
  padding: rem-calc(12) 0 0 0;
  border-bottom: var(--border-width--thin) solid var(--border-color-tertiary);
  font-family: var(--font-primary);
  font-size: rem-calc(14);
  color: var(--text-color-primary);
  &--is-vertical {
    flex-direction: column;
  }
  &--no-border-bottom {
    border-bottom: none;
    padding: 0;
    .st-list-item {
      &__values {
        &__value {
          margin-bottom: rem-calc(5);
          &:nth-child(2n) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &__values {
    display: flex;
    margin-left: auto;
    flex-direction: column;
    &--is-vertical {
      display: block;
      margin-left: 0;
    }
    &__value {
      display: flex;
      align-self: flex-end;
      margin-bottom: rem-calc(10);
      font-weight: 400;
      max-width: rem-calc(200);
      text-align: right;
      line-height: rem-calc(18);
      &:nth-child(2n) {
        margin-bottom: rem-calc(15);
      }
      &--is-semi-bold {
        font-weight: 600;
      }
    }
  }
}
</style>
