<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h56v56H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M45.15 23.427C43.563 15.377 36.493 9.333 28 9.333c-6.743 0-12.6 3.827-15.517 9.427C5.46 19.507 0 25.457 0 32.667c0 7.723 6.277 14 14 14h30.333C50.773 46.667 56 41.44 56 35c0-6.16-4.783-11.153-10.85-11.573zm-12.483 6.906v9.334h-9.334v-9.334h-7L28 18.667l11.667 11.666h-7z"
      />
    </g>
  </svg>
</template>
