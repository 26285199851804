<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h12v12H0z" opacity=".5" />
      <rect width="2" height="12" x="5" :fill="color" rx="1" />
      <rect
        width="2"
        height="12"
        x="5"
        :fill="color"
        rx="1"
        transform="rotate(-90 6 6)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#3E3B39",
    },
  },
};
</script>
