<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M18.752 9.202c-.631 0-1.232.133-1.777.37a78.555 78.555 0 0 1-1.66-3.659.57.57 0 0 0-.56-.35c-.63.037-1.545 0-1.553 0-.316-.027-.582.233-.595.55a.572.572 0 0 0 .55.594c.031.002.655.027 1.258.015.14.33.36.834.624 1.423-.266.383-1.71 2.459-2.406 3.497l-.175.263c-.225.34-.402.599-.584.805L9.285 7.69h.425a.573.573 0 0 0 0-1.145H7.468a.573.573 0 0 0 0 1.145h.528l.486.942-.937 1.307a4.456 4.456 0 0 0-2.122-.535c-2.47 0-4.48 2.01-4.48 4.48 0 2.47 2.01 4.48 4.48 4.48a4.483 4.483 0 0 0 4.44-3.907h.288c1.992 0 2.525-.804 3.262-1.918l.172-.257c.52-.777 1.46-2.136 2.01-2.927.125.266.257.539.39.812a4.472 4.472 0 0 0-1.714 3.517c0 2.47 2.01 4.48 4.48 4.48 2.471 0 4.48-2.01 4.48-4.48 0-2.47-2.01-4.482-4.48-4.482zM7.799 11.55c.463.472.781 1.081.9 1.759H6.54l1.26-1.759zm-2.377 5.666A3.338 3.338 0 0 1 2.09 13.88a3.338 3.338 0 0 1 3.333-3.334c.518 0 1.003.129 1.441.341l-1.906 2.66a.574.574 0 0 0 .465.907h3.276a3.333 3.333 0 0 1-3.276 2.76zm4.44-3.906a4.465 4.465 0 0 0-1.39-2.7l.6-.835 1.795 3.48c-.202.034-.43.054-.716.054h-.29zm8.89 3.706a3.338 3.338 0 0 1-3.335-3.334c0-.976.43-1.848 1.102-2.458.645 1.242 1.313 2.366 1.834 2.87a.575.575 0 0 0 .797-.824c-.43-.416-1.041-1.465-1.66-2.67.39-.16.814-.252 1.262-.252a3.338 3.338 0 0 1 3.333 3.334 3.338 3.338 0 0 1-3.333 3.334z"
      />
    </g>
  </svg>
</template>
