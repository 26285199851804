<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    title="tåg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0H15V16H0z" />
      <path
        fill="#030303"
        fill-opacity=".7"
        fill-rule="nonzero"
        d="M10.41 14.605h-.12c-.264.031-.535.054-.806.065H5.241c-.27-.011-.542-.034-.807-.065H4.3l.012-.017c-.618-.082-1.188-.22-1.605-.412-.046.04-.088.083-.123.128l-.999 1.318c-.143.19-.101.344.093.345l1.358.001c.195 0 .346-.154.49-.344l.072-.09h7.513l.073.09c.143.19.295.344.49.344l1.357-.001c.194 0 .236-.155.093-.345l-1-1.318c-.032-.044-.073-.085-.118-.123-.418.191-.99.328-1.607.41l.01.014zm1.713-9.898c-.015-.324-.074-1.624-1.046-2.282-.504-.342-.98-.36-1.96-.401h-.12C8.744 1.309 8.1.8 7.342.8c-.758 0-1.402.51-1.653 1.224H5.6c-.98.042-1.457.06-1.961.4-.972.659-1.031 1.96-1.046 2.283-.016.328-.59 7.121-.59 7.121.04.351.114 1.134 1.017 1.738.505.338 1.5.53 2.477.572h3.722c.976-.042 1.972-.234 2.477-.572.903-.604.978-1.387 1.017-1.738 0 0-.574-6.793-.59-7.12zm-4.78-2.976c.494 0 .895.427.895.954 0 .528-.4.955-.895.955-.494 0-.895-.427-.895-.955 0-.527.4-.954.895-.954zM3.745 12.607c-.494 0-.895-.427-.895-.954 0-.528.4-.955.895-.955.494 0 .895.427.895.955 0 .527-.4.954-.895.954zm6.262-6.292c-.004.189-.314 2.447-.314 2.447-.023.202-.06.595-.58.942-.292.195-.835.27-1.397.295h-.764c-.562-.025-1.103-.1-1.393-.295-.52-.347-.561-.74-.584-.942 0 0-.31-2.258-.314-2.447-.003-.188-.162-.893.46-1.315.294-.191.566-.206 1.13-.23h2.166c.564.024.836.039 1.13.23.622.422.463 1.127.46 1.315zm.898 6.292c-.494 0-.895-.427-.895-.954 0-.528.401-.955.895-.955s.895.427.895.955c0 .527-.4.954-.895.954z"
      />
    </g>
  </svg>
</template>
