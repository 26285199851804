<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h70v70H0z" opacity=".5" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M53.084 20.542l-6.856 2.748-1.128-.857c-1.245-.946-2.499-1.673-3.854-2.225l-1.331-.541-.202-1.423-.836-5.891h-8.421l-1.038 7.314-1.331.541c-1.326.54-2.614 1.29-3.862 2.23l-1.126.85-1.31-.525-5.54-2.221-4.21 7.267 5.878 4.578-.234 1.46A13.831 13.831 0 0 0 17.485 36c0 .732.057 1.481.165 2.153l.234 1.46-1.166.909-4.71 3.669 4.208 7.267 6.856-2.748 1.128.857c1.245.946 2.499 1.673 3.854 2.225l1.331.541 1.038 7.314h8.421l1.038-7.314 1.331-.541c1.326-.54 2.614-1.29 3.862-2.23l1.126-.85 1.31.525 5.54 2.221 4.212-7.272-5.762-4.542.168-1.393c.096-.801.146-1.536.146-2.251 0-.752-.055-1.47-.165-2.153l-.234-1.46 1.166-.909 4.71-3.669-4.208-7.267zm6.54 22.322c.525.392.656 1.078.328 1.667l-5.244 9.053a1.298 1.298 0 0 1-1.606.588l-6.522-2.614c-1.344 1.013-2.819 1.895-4.425 2.55l-.983 6.928c-.065.654-.622 1.111-1.278 1.111H29.406c-.655 0-1.18-.457-1.311-1.11l-.983-6.93c-1.606-.654-3.048-1.503-4.425-2.55l-6.522 2.615c-.59.197-1.278 0-1.606-.588l-5.244-9.053c-.295-.556-.164-1.275.328-1.667l5.539-4.315c-.131-.817-.197-1.7-.197-2.549 0-.85.098-1.732.23-2.55l-5.54-4.314c-.524-.392-.655-1.078-.327-1.667l5.244-9.053a1.298 1.298 0 0 1 1.606-.588l6.522 2.614c1.344-1.013 2.819-1.895 4.425-2.55l.983-6.928a1.274 1.274 0 0 1 1.278-1.111h10.488c.656 0 1.213.457 1.311 1.11l.983 6.93c1.606.654 3.048 1.503 4.425 2.55l6.522-2.615c.59-.197 1.278 0 1.606.588l5.244 9.053c.295.556.164 1.275-.328 1.667l-5.539 4.315c.131.817.197 1.667.197 2.549 0 .882-.066 1.732-.164 2.55l5.474 4.314zM34.92 45.72c-5.346 0-9.72-4.374-9.72-9.72 0-5.346 4.374-9.72 9.72-9.72 5.346 0 9.72 4.374 9.72 9.72 0 5.346-4.374 9.72-9.72 9.72z"
      />
    </g>
  </svg>
</template>
