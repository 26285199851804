<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M24 0H0v24h24z" />
      <path
        fill="#2B241D"
        d="M14 13v1.865a1 1 0 0 0 1.64.768l3.438-2.865a1 1 0 0 0 0-1.536L15.64 8.367a1 1 0 0 0-1.64.768V11H6a1 1 0 1 0 0 2h8z"
      />
    </g>
  </svg>
</template>
