<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <g fill="none" fill-rule="evenodd">
      <circle cx="11.5" cy="11.5" r="11" fill="#FFF" stroke="#D6D3D0" />
      <circle cx="11.5" cy="6.5" r="1.5" fill="#4D4845" />
      <circle cx="11.5" cy="11.5" r="1.5" fill="#4D4845" />
      <circle cx="11.5" cy="16.5" r="1.5" fill="#4D4845" />
    </g>
  </svg>
</template>
