<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79"
    height="78"
    viewBox="0 0 79 78"
  >
    <g fill="none" fill-rule="evenodd" transform="rotate(-43 56.231 23.616)">
      <rect width="67" height="46" fill="#F6A200" rx="3" />
      <path
        fill="#FFF"
        d="M1.167 32.473h65v3h-65zM8.88 11.005h23v3h-23zM8.166 19.103h28v3h-28z"
      />
    </g>
  </svg>
</template>
