<template>
  <div class="st-expandable-table-component">
    <dl class="st-expandable-table-component__detail-list">
      <div class="st-expandable-table-component__detail-list__container">
        <dt>Kontakt:</dt>
        <dd>Data</dd>
      </div>
      <div class="st-expandable-table-component__detail-list__container">
        <dt>Värdekod:</dt>
        <dd>Data</dd>
      </div>
      <div class="st-expandable-table-component__detail-list__container">
        <dt>Id:</dt>
        <dd>Data</dd>
      </div>
      <div class="st-expandable-table-component__detail-list__container">
        <dt>Typ av värdekod:</dt>
        <dd>Data</dd>
      </div>
      <div class="st-expandable-table-component__detail-list__container">
        <dt>Skapad:</dt>
        <dd>Dada</dd>
      </div>
      <div class="st-expandable-table-component__detail-list__container">
        <dt>Skapad av:</dt>
        <dd>Datttaa</dd>
      </div>
      <div
        class="st-expandable-table-component__detail-list__container"
        v-if="true"
      >
        <dt>Värdekodsreferens:</dt>
        <dd>detdu</dd>
      </div>
    </dl>

    <div class="st-expandable-table-component__button">
      <button tabindex="0">Makulera värdekod</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "st-expandable-table-row",

  props: {
    contact: {
      type: String,
    },
    voucher: {
      type: String,
    },
    id: {
      type: String,
    },
    typeOf: {
      type: String,
    },
    created: {
      type: String,
    },
    createdBy: {
      type: String,
    },
    status: {
      type: Number,
    },
    referredFromVoucher: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-expandable-table-component {
  display: flex;
  flex-direction: column;

  &__detail-list {
    display: flex;
    &__container {
      width: 100%;
    }
  }
}
</style>
