<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="18"
      cy="18"
      r="15.75"
      fill="white"
      stroke="#F7BB0D"
      stroke-width="1.5"
    />
    <path
      d="M13.875 9.37495C12.1562 11.4375 10.4375 13.5 10.4375 13.5L13.875 17.625"
      stroke="#514F4F"
      stroke-width="1.46667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="23"
      y="14.3"
      width="12"
      height="1.5"
      transform="rotate(-180 23 14.3)"
      fill="#514F4F"
    />
    <path
      d="M22.125 26.625C23.8438 24.5625 25.5625 22.5 25.5625 22.5L22.125 18.375"
      stroke="#514F4F"
      stroke-width="1.46667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="13" y="21.7" width="12" height="1.5" fill="#514F4F" />
  </svg>
</template>
<script>
export default {
  name: "StSwap",
};
</script>
