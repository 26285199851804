<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    title="anropsstyrd trafik"
  >
    <path
      fill-rule="evenodd"
      d="M11.896 12.242c-2.463.97-5.047.162-7.327-2.118-2.28-2.28-3.083-4.86-2.111-7.32l-.014-.014.854-.854c.391-.391 1.027-.39 1.41-.006l1.379 1.378c.387.388.385 1.018-.007 1.41l-.146.146c-.39.391-1.027.39-1.41.006l-.355-.355c-.436 1.565.015 3.382 1.321 4.689 1.307 1.306 3.124 1.757 4.689 1.32l-.356-.354c-.387-.388-.386-1.019.006-1.41l.147-.147c.39-.39 1.027-.389 1.41-.006l1.378 1.378c.388.388.386 1.019-.006 1.41l-.855.855z"
      opacity=".7"
    />
  </svg>
</template>
