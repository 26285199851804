<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="32"
    viewBox="0 0 44 32"
  >
    <g fill="none" fill-rule="evenodd">
      <path :fill="color" d="M 15 17 h 14 v -2 H 15 z" />
      <path :fill="color" d="M 23 23 V 9 h -2 v 14 z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "st-icon",
  props: {
    color: {
      type: String,
      default: "#6e6054",
    },
  },
};
</script>
