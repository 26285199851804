<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="87"
    height="80"
    viewBox="0 0 87 80"
  >
    <g fill="none" fill-rule="evenodd">
      <g stroke="#F6A200" transform="translate(57 50)">
        <circle cx="14.5" cy="14.5" r="14.5" stroke-width="2" />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
          d="M8 15.272L13.169 21 22 9"
        />
      </g>
      <path
        fill="#F6A200"
        d="M80.096 23.575c-1.13-1.312-2.236-2.648-3.38-3.949-2.17-2.466-4.286-4.98-6.43-7.47-1.032-1.197-2.03-2.422-3.085-3.6-1.219-1.364-2.39-2.772-3.579-4.164-.774-.907-1.526-1.836-2.317-2.729-.575-.65-1.267-1.162-2.1-1.407-2.156-.634-4.073-.059-5.73 1.377-2.16 1.872-4.255 3.823-6.38 5.737-2.291 2.062-4.584 4.12-6.88 6.177-.183.164-.241.333-.206.587.12.855.371 1.686.374 2.563.006 2.105-1.933 3.757-3.953 3.356-.662-.132-1.288-.357-1.91-.608-.374-.15-.694-.183-1.04.149-.88.84-1.8 1.636-2.705 2.448-1.924 1.725-3.852 3.445-5.773 5.173-2.363 2.125-4.719 4.26-7.083 6.384-2.6 2.337-5.208 4.665-7.809 7.002-2.672 2.4-5.356 4.79-8.003 7.22C.844 48.979.099 50.44 0 52.207l.009.863c.08.976.41 1.852 1.04 2.591 1.052 1.235 2.079 2.49 3.163 3.698 1.102 1.227 2.135 2.516 3.222 3.757 2.137 2.44 4.239 4.912 6.349 7.377 1.93 2.254 3.844 4.52 5.781 6.768a4.49 4.49 0 0 0 1.382 1.099c2.155 1.07 4.202.765 6.083-.664.908-.69 1.72-1.514 2.575-2.279 1.925-1.723 3.851-3.446 5.773-5.173 2.147-1.932 4.287-3.871 6.435-5.802 1.922-1.728 3.852-3.445 5.774-5.173 1.921-1.727 3.835-3.464 5.755-5.194 1.776-1.6 3.55-3.202 5.335-4.792.277-.247.367-.52.339-.884-.063-.808-.227-1.605-.223-2.42.01-1.893 1.523-3.473 3.374-3.512.765-.016 1.487.193 2.2.44.67.23 1.24.28 1.807-.342.66-.722 1.447-1.322 2.178-1.977 1.687-1.513 3.374-3.026 5.06-4.541 1.737-1.56 3.46-3.14 5.214-4.68 1.526-1.339 2.394-2.98 2.375-5.064-.01-1.006-.25-1.969-.904-2.728z"
      />
      <path
        fill="#FFF"
        d="M43.393 26.998c-1.241-.053-2.306-.98-2.388-2.317-.096-1.568 1.142-2.703 2.547-2.68 1.2.019 2.482 1.062 2.447 2.472a2.467 2.467 0 0 1-2.606 2.525zM49.401 33.996c-1.303-.068-2.465-1.062-2.398-2.626.055-1.312 1.099-2.386 2.543-2.37 1.26.014 2.476 1.074 2.452 2.458.055 1.341-1.076 2.619-2.597 2.538zM55.382 40.996c-1.245-.07-2.421-1.006-2.381-2.6.033-1.294 1.102-2.421 2.57-2.396 1.23.022 2.464 1.1 2.427 2.455a2.467 2.467 0 0 1-2.616 2.54z"
      />
    </g>
  </svg>
</template>
