<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    role="img"
    aria-labelledby="adultTitle"
  >
    <title id="adultTitle">En vuxen</title>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g fill="#6E6054" fill-rule="nonzero" transform="translate(6 3)">
        <path
          d="M11.22 14.27l-.891-5.041c-.211-1.2-1.217-2.193-2.573-2.685a3.84 3.84 0 0 0-.283-.1 1.182 1.182 0 0 0-.7.007 3.432 3.432 0 0 1-1.96.014 1.188 1.188 0 0 0-.695.001c-.074.024-.146.05-.217.077-1.356.493-2.362 1.485-2.573 2.686L.4 14.63c-.087.442-.007 1.082.37 1.514.272.311.663.476 1.132.476h.31c.12 0 .344-.07.36-.353 0 0 .293-5.054.306-5.303.01-.192.203-.338.382-.33.202.01.356.173.346.365-.014.267-.2 3.483-.304 5.261-.011.197.154.36.36.36h4.324c.207 0 .371-.164.36-.36-.103-1.778-.29-4.994-.303-5.261a.355.355 0 0 1 .345-.364c.182-.01.373.138.382.33.014.268.172 3.54.307 5.305.026.35.296.35.296.35h.133c.617 0 1.084-.174 1.389-.517.359-.402.469-1.02.326-1.834z"
        />
        <circle cx="5.765" cy="3.154" r="2.825" />
      </g>
    </g>
  </svg>
</template>
