<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#ED5A53"
        d="M10.023 0c.92.013 1.718.075 2.49.295.616.175 1.229.357 1.809.645.37.183.742.36 1.09.582a9.713 9.713 0 0 1 1.797 1.467c.513.536.988 1.103 1.369 1.744.733 1.236 1.221 2.562 1.368 3.998a10.372 10.372 0 0 1-.252 3.562 9.994 9.994 0 0 1-1.463 3.285 9.863 9.863 0 0 1-2.137 2.274c-.93.713-1.934 1.278-3.044 1.644-.944.311-1.911.47-2.905.5a9.637 9.637 0 0 1-3.164-.418c-1.845-.573-3.408-1.593-4.662-3.078-1.2-1.422-1.954-3.062-2.222-4.913-.185-1.28-.094-2.553.182-3.817.079-.356.171-.709.305-1.04.132-.328.233-.67.416-.982.133-.224.233-.473.365-.7.304-.52.66-1.002 1.05-1.46A10.19 10.19 0 0 1 5.6 1.085 9.93 9.93 0 0 1 10.023 0z"
      />
      <path
        fill="#FFF"
        d="M14.709 6.539c-.099-.022-.246-.026-.37-.033-.652-.035-1.29.084-1.92.25-.104.028-.129.077-.123.174.016.242.027.483.034.725.004.157-.012.316.054.476a4.78 4.78 0 0 1 1.206-.244c.33-.021.657-.039.98.052.28.08.503.29.529.51.048.412-.096.634-.5.803a1.47 1.47 0 0 1-.551.113c-.24.003-.48-.004-.72-.01-.101-.003-.12.01-.114.212.007.203 0 .52-.007.76s-.003.236.131.225c.19-.014.379-.023.568-.011.274.018.554.015.818.12.42.166.588.624.353 1.011-.15.245-.405.35-.67.402a3.864 3.864 0 0 1-2.13-.177c-.168-.062-.18-.062-.19.124-.015.324-.057.646-.077.97-.018.275-.043.281.228.338.803.168 1.613.22 2.43.122a4.091 4.091 0 0 0 1.14-.294c.532-.229.934-.595 1.097-1.164.247-.855.034-1.666-.891-2.026-.102-.04-.224-.027-.312-.115.05-.055.116-.057.173-.076.819-.27 1.243-1.055 1.012-1.883a1.385 1.385 0 0 0-.391-.665c-.139-.201-.584-.43-1.04-.56l-.747-.13zM8.918 8.691c.102 1.54.205 3.081.301 4.622.009.14.053.185.197.183.468-.006.937-.007 1.405.004.155.003.188-.047.177-.184-.069-.839-.133-1.678-.196-2.517a927.821 927.821 0 0 1-.3-4.122c-.01-.133-.056-.174-.191-.173-.736.005-1.472.003-2.208-.001-.116-.001-.166.032-.193.144-.183.785-.373 1.567-.56 2.351-.093.384-.184.769-.275 1.153a5.9 5.9 0 0 1-.161-.694c-.177-.932-.355-1.864-.528-2.797-.021-.117-.07-.16-.197-.16-.775.005-1.55.005-2.325.002-.116-.001-.174.028-.18.149-.023.484-.126.96-.157 1.444-.054.834-.16 1.665-.245 2.497-.093.912-.184 1.825-.28 2.737-.013.123.035.162.157.162.485-.003.97-.002 1.455.003.11 0 .155-.03.165-.144.106-1.185.218-2.37.328-3.555l.113-1.21c.025.053.038.07.042.09l.376 1.699c.218.983.44 1.967.654 2.951.028.13.084.176.223.17.304-.016.61-.039.912-.014.178.014.198-.063.229-.182.22-.847.448-1.693.663-2.541.13-.51.28-1.013.387-1.53.04-.195.095-.4.194-.633.012.065.017.08.018.096z"
      />
    </g>
  </svg>
</template>
