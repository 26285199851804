<template>
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="clipLarge">
      <path
        d="M120.222 60C120.222 93.1371 93.3594 120 60.2224 120C27.0853 120 0.222351 93.1371 0.222351 60C0.222351 26.8629 27.0853 0 60.2224 0C93.3594 0 120.222 26.8629 120.222 60ZM17.2107 60C17.2107 83.7547 36.4677 103.012 60.2224 103.012C83.977 103.012 103.234 83.7547 103.234 60C103.234 36.2453 83.977 16.9884 60.2224 16.9884C36.4677 16.9884 17.2107 36.2453 17.2107 60Z"
        fill="white"
      />
    </clipPath>

    <foreignObject
      x="0"
      y="0"
      width="120"
      height="120"
      clip-path="url(#clipLarge)"
    >
      <div class="gradient" xmlns="http://www.w3.org/1999/xhtml"></div>
    </foreignObject>
    <circle
      cx="59.5"
      cy="8.5"
      r="8.5"
      transform="rotate(180 59.5 8.5)"
      fill="var(--background-color-primary)"
    />
  </svg>
</template>
<style lang="scss" scoped>
@import "../../scss/main.scss";
.gradient {
  width: rem-calc(120);
  height: rem-calc(120);
  border-radius: 50%;
  background: conic-gradient(transparent, var(--background-color-primary));
}
</style>
