<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8983 6.17005C14.8983 5.66293 15.3612 5.25184 15.9322 5.25184H20.0676C20.6386 5.25184 21.1014 5.66293 21.1014 6.17005V6.73848H14.8983V6.17005ZM12.8307 6.73848V6.17005C12.8307 4.6487 14.2193 3.41541 15.9322 3.41541H20.0676C21.7805 3.41541 23.1691 4.6487 23.1691 6.17005V6.73848H30.3428C31.479 6.73848 32.4 7.73034 32.4 8.95387V12.7333C32.1608 14.4017 30.8216 15.679 29.2053 15.679H6.79446C5.17858 15.679 3.8396 14.4022 3.59998 12.7343V8.95387C3.59998 7.73034 4.52099 6.73848 5.65712 6.73848H12.8307ZM32.4 15.2347C31.6192 16.1865 30.4774 16.7867 29.2053 16.7867H21.0857V18.9231C21.0857 19.5349 20.6252 20.0308 20.0571 20.0308H15.9428C15.3748 20.0308 14.9143 19.5349 14.9143 18.9231V16.7867H6.79446C5.52253 16.7867 4.38075 16.1866 3.59998 15.2349V23.3539C3.59998 25.8009 5.442 27.7846 7.71426 27.7846H28.2857C30.5579 27.7846 32.4 25.8009 32.4 23.3539V15.2347ZM15.9428 16.7867H20.0571V18.9231H15.9428V16.7867Z"
      fill="#6E6055"
    />
  </svg>
</template>
