<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    title="buss"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0H15V16H0z" />
      <path
        fill="#030303"
        fill-opacity=".7"
        fill-rule="nonzero"
        d="M12.558 4.888h-.249l-.123-1.794c-.04-.35-.175-1.132-1.076-1.734-.504-.337-1.741-.518-2.715-.56H6.273c-.974.042-1.814.223-2.318.56-.9.602-1.066 1.383-1.106 1.734L2.73 4.888h-.26c-.329 0-.595.284-.595.634v1.81c0 .291.329.826.629.997-.106 1.618-.195 3.017-.198 3.149-.006.318-.264 1.487.725 2.22v1.17c0 .176.133.318.297.318h.842c.164 0 .297-.142.297-.317v-.745c.176.012.37.02.592.03h4.856c.211-.009.392-.018.557-.028v.743c0 .175.133.317.297.317h.842c.164 0 .297-.142.297-.317v-1.19c.962-.735.765-1.885.76-2.201-.005-.22-.007-.847-.14-3.152.298-.174.624-.704.624-.994v-1.81c0-.35-.266-.634-.594-.634zM5.52 2.292c0-.175.133-.317.297-.317h3.317c.164 0 .297.142.297.317v.264c0 .175-.133.317-.297.317H5.818c-.164 0-.297-.142-.297-.317v-.264zm-1.43 10.325c-.478 0-.866-.414-.866-.925 0-.51.388-.924.866-.924.479 0 .867.414.867.924s-.388.925-.867.925zm5.275-2.99c-.313.21-.898.29-1.503.316h-.821c-.605-.025-1.187-.107-1.5-.316-.56-.374-.603-.796-.627-1.014 0 0-.334-2.43-.338-2.632-.004-.203-.174-.961.496-1.415.315-.206.607-.222 1.214-.248h2.33c.608.026.9.042 1.215.248.67.454.5 1.212.496 1.415-.004.203-.338 2.632-.338 2.632-.024.218-.065.64-.624 1.014zm.652 2.065c0-.51.388-.924.866-.924.479 0 .867.414.867.924s-.388.925-.867.925c-.478 0-.866-.414-.866-.925z"
      />
    </g>
  </svg>
</template>
