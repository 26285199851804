<template>
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99984 0H25.9998C26.3535 0 26.6926 0.140476 26.9426 0.390524C27.1927 0.640573 27.3332 0.979711 27.3332 1.33333V22.6667C27.3332 23.0203 27.1927 23.3594 26.9426 23.6095C26.6926 23.8595 26.3535 24 25.9998 24H1.99984C1.64622 24 1.30708 23.8595 1.05703 23.6095C0.80698 23.3594 0.666504 23.0203 0.666504 22.6667V1.33333C0.666504 0.979711 0.80698 0.640573 1.05703 0.390524C1.30708 0.140476 1.64622 0 1.99984 0ZM24.6665 10.6667H3.33317V21.3333H24.6665V10.6667ZM24.6665 8V2.66667H3.33317V8H24.6665ZM16.6665 16H21.9998V18.6667H16.6665V16Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#5C5957",
    },
  },
};
</script>
