<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="23"
    viewBox="0 0 26 23"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0-2h26v26H0z" />
      <path fill="#514F4F" d="M0 20.348h26v1.938H0z" />
      <path
        fill="#514F4F"
        d="M17.954 3.113c.118.662.45 1.56.45 1.56h-3.12l1.97 16.34H15.6l-2.167-17.9h4.52z"
      />
      <path
        fill="#514F4F"
        d="M22.967 21.012v-17.9h-4.334l1.805 17.9H19.5L17.745 1.556H24.7v19.456h-1.733z"
      />
      <path
        fill="#514F4F"
        d="M16.9 6.226h5.2v1h-5.2zM16.9 8.561h5.2v1h-5.2zM16.9 10.895h5.2v1h-5.2zM16.9 13.23h5.2v1h-5.2zM16.9 15.565h5.2v1h-5.2z"
      />
      <path stroke="#514F4F" d="M19.133.5h3.333v2.113h-3.333z" />
      <path
        fill="#514F4F"
        d="M14.083 4.928l2.207 16.389 4.293-.168-2.12-16.304z"
        opacity=".1"
      />
      <g transform="translate(2.167 7.752)">
        <path
          fill="#514F4F"
          d="M4.34 1.69c-.173.041-.341.094-.503.157-.221-.16-.5-.255-.804-.255-.718 0-1.3.534-1.3 1.194 0 .278.104.535.279.738-.07.148-.127.303-.171.462a.952.952 0 0 0-.108-.006c-.478 0-.866.356-.866.796 0 .44.388.795.866.795a.952.952 0 0 0 .108-.006c.044.16.101.314.17.462a1.13 1.13 0 0 0-.278.738c0 .66.582 1.194 1.3 1.194.304 0 .583-.095.804-.255.162.063.33.116.503.156a.74.74 0 0 0-.007.1c0 .439.388.795.867.795s.867-.356.867-.796a.74.74 0 0 0-.007-.099c.173-.04.341-.093.503-.156.221.16.5.255.804.255.718 0 1.3-.534 1.3-1.194a1.13 1.13 0 0 0-.279-.738c.07-.148.127-.303.171-.462a.952.952 0 0 0 .108.006c.478 0 .866-.356.866-.795 0-.44-.388-.796-.866-.796a.952.952 0 0 0-.108.006 2.986 2.986 0 0 0-.17-.462 1.13 1.13 0 0 0 .278-.738c0-.66-.582-1.194-1.3-1.194-.304 0-.583.095-.804.255a3.655 3.655 0 0 0-.503-.156.74.74 0 0 0 .007-.1c0-.439-.388-.795-.867-.795s-.867.356-.867.796a.74.74 0 0 0 .007.099zM.956 6.2C.389 5.937 0 5.398 0 4.776c0-.623.389-1.162.956-1.424a1.84 1.84 0 0 1-.09-.566c0-1.1.97-1.99 2.167-1.99.215 0 .422.029.617.082C3.935.358 4.522 0 5.2 0s1.265.357 1.55.878c.195-.053.402-.082.617-.082 1.196 0 2.166.89 2.166 1.99 0 .197-.03.387-.089.566.567.262.956.801.956 1.424 0 .622-.389 1.16-.956 1.423.058.18.09.37.09.566 0 1.1-.97 1.99-2.167 1.99-.215 0-.422-.028-.617-.082-.285.52-.872.878-1.55.878s-1.265-.357-1.55-.878a2.346 2.346 0 0 1-.617.082c-1.196 0-2.166-.89-2.166-1.99 0-.197.03-.387.089-.566z"
        />
        <path fill="#514F4F" d="M4.767 8.755h1v5.571h-1z" />
        <ellipse
          cx="5.2"
          cy="4.776"
          fill="#FDCE1B"
          opacity=".8"
          rx="1.733"
          ry="1.592"
        />
        <path
          fill="#514F4F"
          d="M5.2 7.163c-1.436 0-2.6-1.069-2.6-2.387 0-1.32 1.164-2.388 2.6-2.388 1.436 0 2.6 1.069 2.6 2.388 0 1.318-1.164 2.387-2.6 2.387zm0-.796c.957 0 1.733-.712 1.733-1.591 0-.88-.776-1.592-1.733-1.592-.957 0-1.733.712-1.733 1.592 0 .879.776 1.591 1.733 1.591z"
        />
        <path
          stroke="#514F4F"
          d="M8.415 10.095c-.605-.103-1.2.069-1.614.482-.414.414-.585 1.01-.482 1.614.604.103 1.2-.069 1.613-.482.414-.414.586-1.01.483-1.614zM1.602 10.76c.213.574.66 1.004 1.224 1.156.565.151 1.167.002 1.64-.39-.214-.574-.66-1.004-1.226-1.156-.565-.151-1.166-.002-1.638.39z"
        />
      </g>
    </g>
  </svg>
</template>
