<template>
  <svg
    width="62"
    height="56"
    viewBox="0 0 62 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54 6H6V42H30C30.85 42 31.5625 42.2875 32.1375 42.8625C32.7125 43.4375 33 44.15 33 45C33 45.85 32.7125 46.5625 32.1375 47.1375C31.5625 47.7125 30.85 48 30 48H6C4.35 48 2.9375 47.4125 1.7625 46.2375C0.5875 45.0625 0 43.65 0 42V6C0 4.35 0.5875 2.9375 1.7625 1.7625C2.9375 0.5875 4.35 0 6 0H54C55.65 0 57.0625 0.5875 58.2375 1.7625C59.4125 2.9375 60 4.35 60 6V24C60 24.85 59.7125 25.5625 59.1375 26.1375C58.5625 26.7125 57.85 27 57 27C56.15 27 55.4375 26.7125 54.8625 26.1375C54.2875 25.5625 54 24.85 54 24V6ZM6 6V42V27V27.225V6ZM30 21L54 6V12L31.575 26.025C31.075 26.325 30.55 26.475 30 26.475C29.45 26.475 28.925 26.325 28.425 26.025L6 12V6L30 21ZM51.525 48H42C41.15 48 40.4375 47.7125 39.8625 47.1375C39.2875 46.5625 39 45.85 39 45C39 44.15 39.2875 43.4375 39.8625 42.8625C40.4375 42.2875 41.15 42 42 42H51.525L48.825 39.3C48.225 38.7 47.9375 38 47.9625 37.2C47.9875 36.4 48.3 35.7 48.9 35.1C49.5 34.55 50.2 34.2625 51 34.2375C51.8 34.2125 52.5 34.5 53.1 35.1L60.9 42.9C61.2 43.2 61.425 43.525 61.575 43.875C61.725 44.225 61.8 44.6 61.8 45C61.8 45.4 61.725 45.775 61.575 46.125C61.425 46.475 61.2 46.8 60.9 47.1L53.1 54.9C52.55 55.45 51.8625 55.7375 51.0375 55.7625C50.2125 55.7875 49.5 55.5 48.9 54.9C48.35 54.35 48.075 53.65 48.075 52.8C48.075 51.95 48.35 51.25 48.9 50.7L51.525 48Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "st-mail-send-big",
  props: {
    color: {
      type: String,
      default: "#3E3B39",
    },
  },
};
</script>
