<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g transform="translate(3 3)">
        <circle
          cx="7.5"
          cy="7.5"
          r="6.556"
          :stroke="color"
          stroke-width="1.888"
        />
        <path
          :fill="color"
          d="M11.705 13.12a.996.996 0 0 1 .002-1.413 1.003 1.003 0 0 1 1.412-.002l4.247 4.247a.996.996 0 0 1-.002 1.412c-.39.39-1.027.387-1.412.002l-4.247-4.247z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6E6054",
    },
  },
};
</script>
