<template>
  <div class="st-table-mobile">
    <label class="st-table-mobile__label" for="sort-select" id="sort-label"
      >Sortera tabell på</label
    >
    <st-select
      :items="sortingOptions"
      id="sort-select"
      :has-empty="false"
      v-model="sortBy"
    />
    <div
      class="st-table-mobile__radio-btns"
      role="radiogroup"
      aria-labelledby="sort-label"
    >
      <st-radio-button v-model="sortOrder" :value="'asc'" tabindex="0">
        Stigande
      </st-radio-button>
      <st-radio-button v-model="sortOrder" :value="'desc'" tabindex="0">
        Fallande
      </st-radio-button>
    </div>
    <div class="st-table-mobile__radio-btns__search-button">
      <st-button @click="handleSort">Sortera</st-button>
    </div>
  </div>
</template>
<script>
import StSelect from "../select/Select.vue";
import StRadioButton from "../radio-button/RadioButton.vue";
import StButton from "../button/Button.vue";
import { computed, ref } from "vue";
export default {
  components: { StSelect, StRadioButton, StButton },
  props: {
    headers: Array,
  },
  setup(props, { emit }) {
    const sortingOptions = computed(() => {
      const sortableHeaders = props.headers.filter((header) => header.sortBy);
      const options = sortableHeaders.map((header) => {
        return { id: header.sortBy, name: header.name };
      });
      return options;
    });

    const sortBy = ref(sortingOptions.value[0].id);
    const sortOrder = ref("asc");

    const handleSort = () => {
      emit("sort", sortBy.value, sortOrder.value);
    };

    return {
      sortOrder,
      sortBy,
      sortingOptions,
      handleSort,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-table-mobile {
  user-select: none;
  display: flex;
  flex-direction: column;
  @include large {
    display: none;
  }

  &__label {
    font-weight: 600;
    font-size: rem-calc(14);
    color: var(--text-color-primary);
  }

  &__radio-btns {
    display: flex;
    margin: rem-calc(15) 0;
    & > :not(:last-child) {
      margin-right: rem-calc(100);
    }
  }

  & > :last-child {
    margin-bottom: rem-calc(15);
    width: 50%;
  }
}
</style>
