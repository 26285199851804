<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h70v70H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M51.69 30.557c-1.502-8.39-8.91-14.552-17.69-14.552-6.757 0-13.089 3.712-16.917 9.584l-.72 1.106-1.302.215C8.345 28.024 3 34.124 3 40.973c0 7.776 6.253 14.022 14 14.022h36.429c6.59 0 11.571-4.976 11.571-11.59 0-5.34-4.616-10.013-10.542-10.379l-2.352-.145-.416-2.324zm2.953-.53C62.192 30.494 68 36.513 68 43.405 68 51.7 61.653 58 53.429 58H17C7.622 58 0 50.434 0 40.973c0-8.539 6.63-15.71 14.571-17.027C18.7 17.612 25.811 13 34 13c10.313 0 18.898 7.284 20.643 17.027zm-15.716 8.784v8.054a1 1 0 0 1-1 1h-8.259a1 1 0 0 1-1-1V38.81h-4.163a1 1 0 0 1-.744-1.669l9.293-10.341a1 1 0 0 1 1.487 0l9.293 10.341a1 1 0 0 1-.744 1.669h-4.163z"
      />
    </g>
  </svg>
</template>
