<template>
  <a
    v-if="!isRouteLink"
    :class="classList"
    :href="link"
    :tabindex="tabindex"
    :target="isExternal ? '_blank' : '_self'"
    role="link"
  >
    <slot />
  </a>
  <router-link
    v-else
    :class="classList"
    :to="link"
    :tabindex="tabindex"
    role="link"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "st-link",
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    isRouteLink: {
      type: Boolean,
    },
    link: {
      type: String,
      required: true,
    },
    tabindex: {
      type: String,
      default: "0",
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      const link = "st-link";
      return [link, this.primary && `${link}--is-primary`];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-link {
  background-color: var(--background-color-secondary);
  display: flex;
  transition: background-color 0.2s ease;
  border: var(--border-width) solid var(--border-color-primary);
  border-radius: var(--border-radius);
  font-family: var(--font-primary) !important;
  padding: 0 rem-calc(17) !important; // Företag has a padding on all anchor tags that we have to override.
  float: left;
  text-decoration: none;
  &:not(&--is-small) {
    min-height: rem-calc(48);
  }
  line-height: rem-calc(48);
  font-size: rem-calc(18);
  @include medium {
    padding: 0 rem-calc(34);
  }
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-color-quinary);
  justify-content: center;
  align-items: center;
  position: relative;

  &:focus {
    box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
      0 0 0 rem-calc(4) var(--border-color-quaternary);
    border-radius: rem-calc(6);
  }

  &:hover:not(:disabled) {
    border: var(--border-width) solid var(--border-color-secondary);
  }

  &:hover:not(:disabled),
  &:focus {
    outline: none;
    cursor: pointer;
  }

  &:disabled {
    border-color: var(--border-color-tertiary);
    color: var(--disabled-color-primary);
    cursor: not-allowed;
  }

  &:hover:not(:disabled),
  &:active {
    background-color: var(--background-color-secondary);
    color: var(--disabled-color-primary);
  }
  &--is-primary {
    color: var(--text-color-tertiary);
    background-color: var(--background-color-primary);

    &:disabled {
      background-color: var(--background-color-tertiary);
      border-color: var(--background-color-tertiary);
      color: var(--text-color-tertiary);
    }

    &:focus {
      box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
        0 0 0 rem-calc(4) var(--border-color-quaternary);
      border-radius: rem-calc(6);
      border-color: transparent;
    }

    &:hover:not(:disabled) {
      background-color: var(--background-color-quaternary);
      border-color: var(--background-color-quaternary);
      box-shadow: none;
      color: var(--text-color-primary);
    }
  }
}
</style>
