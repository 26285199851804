<template>
  <button
    :type="type"
    :class="classList"
    :id="id"
    :disabled="disabled || isLoading"
    :tabindex="tabindex"
    :aria-label="ariaLabel"
    @mouseenter="isHoveredOn = true"
    @mouseleave="isHoveredOn = false"
  >
    <st-spinner
      v-if="isLoading"
      :loading-message="srLoadingMessage"
      small
      :secondary="!primary"
    />
    <slot :is-hovered-on="isHoveredOn">default text</slot>
  </button>
</template>

<script>
import { ref } from "vue";
import StSpinner from "../spinner/Spinner";
export default {
  name: "st-button",
  props: {
    type: {
      type: String,
      default: "button",
      validator: function (type) {
        return ["submit", "button", "reset"].includes(type);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: "0",
    },
    primary: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: "",
    },
    srLoadingMessage: {
      type: String,
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },
  components: {
    StSpinner,
  },
  computed: {
    classList: function () {
      const button = "st-button";
      return [
        button,
        this.primary && `${button}--is-primary`,
        this.fullWidth && `${button}--is-full-width`,
        this.small && `${button}--is-small`,
        this.isLoading && `${button}--is-loading`,
      ];
    },
  },
  setup() {
    const isHoveredOn = ref(false);
    return { isHoveredOn };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-button {
  background-color: var(--background-color-secondary);
  display: flex;
  transition: background-color 0.2s ease;
  border: var(--border-width) solid var(--border-color-primary);
  border-radius: var(--border-radius);
  font-family: var(--font-primary);
  padding: 0 rem-calc(17);
  &:not(&--is-small) {
    min-height: rem-calc(48);
  }
  @include medium {
    padding: 0 rem-calc(34);
  }
  font-size: rem-calc(18);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: rem-calc(27);
  letter-spacing: normal;
  text-align: center;
  color: var(--text-color-quinary);
  justify-content: center;
  align-items: center;
  position: relative;

  &:focus {
    box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
      0 0 0 rem-calc(4) var(--border-color-quaternary);
    border-radius: rem-calc(6);
  }

  &:hover:not(:disabled) {
    border: var(--border-width) solid var(--border-color-secondary);
  }

  &:hover:not(:disabled),
  &:focus {
    outline: none;
    cursor: pointer;
  }

  &:disabled {
    border-color: var(--border-color-tertiary);
    color: var(--disabled-color-primary);
    cursor: not-allowed;
  }

  &:hover:not(:disabled),
  &:active {
    background-color: var(--background-color-secondary);
    color: var(--text-color-primary);
  }

  &--is-small {
    padding: rem-calc(7) rem-calc(9);
    font-size: rem-calc(14);
    height: rem-calc(32);
    line-height: rem-calc(16);
  }
  &--is-full-width {
    width: 100%;
  }
  &--is-primary {
    color: var(--text-color-tertiary);
    background-color: var(--background-color-primary);

    &:disabled {
      background-color: var(--background-color-tertiary);
      border-color: var(--background-color-tertiary);
      color: var(--text-color-tertiary);
    }

    &:focus {
      box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
        0 0 0 rem-calc(4) var(--border-color-quaternary);
      border-radius: rem-calc(6);
      border-color: transparent;
    }

    &:hover:not(:disabled) {
      background-color: var(--background-color-quaternary);
      border-color: var(--background-color-quaternary);
      box-shadow: none;
      color: var(--text-color-primary);
    }
  }
  &--is-loading {
    color: transparent !important;
    & > .st-spinner {
      position: absolute;
    }
    & > .st-icon {
      opacity: 0;
    }
  }
}
</style>
