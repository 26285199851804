<template>
  <div :class="classList">
    <div class="st-tabs__list" role="tablist" :aria-label="ariaLabel">
      <button
        v-for="(tab, index) in tabs"
        :key="tab.type"
        @click="activateTab(index)"
        class="st-tabs__list__tab"
        :class="{ 'st-tabs__list__tab--is-active': activeTab === index }"
        role="tab"
        :aria-selected="activeTab === index"
        :aria-controls="`${tab.type}-tabb`"
        :id="`st-tab-${tab.type}`"
        tabindex="0"
      >
        <div
          class="st-tabs__list__tab__name"
          :class="{ 'st-tabs__list__tab__name--reversed': tab.iconReversed }"
        >
          <st-icon
            v-if="tab.iconName"
            :name="tab.iconName"
            :icon-props="tab.iconProps"
          />
          <span>
            {{ capitalize(tab.name) }}
          </span>
        </div>
      </button>
    </div>
    <div
      class="st-tabs__content"
      role="tabpanel"
      :id="`${tabs[activeTab].type}-tabb`"
      :aria-labelledby="`st-tab-${tabs[activeTab].type}`"
    >
      <slot :name="tabs[activeTab].type"></slot>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { capitalize } from "../../helpers";
import StIcon from "../icon/Icon.vue";

export default {
  name: "st-tabs",
  props: {
    tabs: Array,
    ariaLabel: String,
    font: {
      type: String,
      validator(font) {
        return ["primary"].includes(font);
      },
    },
  },
  components: {
    StIcon,
  },
  setup(props) {
    const activeTab = ref(0);

    function activateTab(index) {
      activeTab.value = index;
    }

    const classList = computed(() => {
      return ["st-tabs", props.font && `st-tabs--font-${props.font}`];
    });

    return {
      activeTab,
      activateTab,
      capitalize,
      classList,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: var(--font-secondary);
  color: var(--text-color-primary);
  &--font-primary {
    font-family: var(--font-primary);
  }

  &__list {
    line-height: rem-calc(18);
    margin-bottom: rem-calc(16);
    border-bottom: rem-calc(1) solid var(--border-color-tertiary);
    overflow-x: auto;
    display: flex;
    &__tab {
      background: none;
      border: rem-calc(1) solid transparent;
      font-family: inherit;
      color: inherit;
      padding: 0 rem-calc(8);
      transition: 300ms linear background;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: var(--border-color-quaternary);
      }
      &:hover {
        background: var(--background-color-quaternary);
      }
      &__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: rem-calc(14);
        height: rem-calc(28);
        padding: rem-calc(4) 0;
        line-height: rem-calc(29);
        border-bottom: rem-calc(2) solid transparent;
        white-space: nowrap;
        transition: 300ms linear border-bottom;

        @include large {
          font-size: rem-calc(18);
        }

        & > :first-child {
          margin-right: rem-calc(4);
        }

        &--reversed {
          flex-direction: row-reverse;

          & > :first-child {
            margin-right: 0;
            margin-left: rem-calc(4);
          }
        }
      }
      &--is-active {
        > .st-tabs__list__tab__name {
          font-weight: 600;
          border-color: var(--border-color-primary);
        }
      }
    }
  }
}
</style>
