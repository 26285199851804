<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.51471"
      y="22.071"
      width="22"
      height="2"
      rx="1"
      transform="rotate(-45 6.51471 22.071)"
      :fill="color"
    />
    <rect
      x="7.92892"
      y="6.51465"
      width="22"
      height="2"
      rx="1"
      transform="rotate(45 7.92892 6.51465)"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "st-close",
  props: {
    color: {
      type: String,
      default: "#3e3b39",
    },
  },
};
</script>
