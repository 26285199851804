<template>
  <nav aria-label="länkstig" class="st-breadcrumb">
    <ol class="st-breadcrumb__list">
      <li
        class="st-breadcrumb__list__item"
        v-for="(item, index) in items"
        :key="item.text"
      >
        <span
          class="st-breadcrumb__list__item__link st-breadcrumb__list__item__link--is-active"
          aria-current="page"
          v-if="index === items.length - 1"
        >
          {{ item.text }}
        </span>
        <router-link
          class="st-breadcrumb__list__item__link"
          v-else
          :to="item.link"
          tabindex="0"
          >{{ item.text }}</router-link
        >
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "st-breadcrumb",
  props: {
    items: Array,
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-breadcrumb {
  background: var(--background-color-tertiary);
  color: var(--text-color-primary);
  font-weight: 600;
  font-size: rem-calc(14);
  font-family: var(--font-primary);
  display: flex;
  padding: 0 rem-calc(22);
  height: rem-calc(32);
  line-height: rem-calc(32);
  &__list {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    &__item {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      &:not(:last-child) {
        &::after {
          border-color: var(--border-color-quaternary);
          border-style: solid;
          border-width: 0 0 rem-calc(2) rem-calc(2);
          content: "";
          display: block;
          height: rem-calc(6);
          width: rem-calc(6);
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
          margin: 0 rem-calc(10);
        }
      }
      &__link {
        text-decoration: none;
        color: var(--text-color-primary);
        &--is-active {
          pointer-events: none;
          cursor: default;
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
