<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.40039 11.2C2.40039 10.3163 3.11673 9.59998 4.00039 9.59998H5.60039V14.4H4.00039C3.11674 14.4 2.40039 13.6836 2.40039 12.8V11.2Z"
      fill="#514F4F"
    />
    <path
      d="M11.4178 4.66898L6.40039 10V14L11.4178 19.331C11.9144 19.8586 12.8004 19.5072 12.8004 18.7827V5.21727C12.8004 4.49281 11.9144 4.14142 11.4178 4.66898Z"
      fill="#514F4F"
    />
    <path
      d="M14.4004 8C15.8549 8 18.4004 9.33333 18.4004 12C18.4004 14.6667 15.8549 16 14.4004 16"
      stroke="#514F4F"
      stroke-width="1.2"
    />
    <path
      d="M14.4004 10.4C14.9822 10.4 16.0004 10.9334 16.0004 12C16.0004 13.0667 14.9822 13.6 14.4004 13.6"
      stroke="#514F4F"
      stroke-width="1.2"
    />
    <path
      d="M14.4004 5.59998C16.7277 5.59998 20.8004 7.73331 20.8004 12C20.8004 16.2666 16.7277 18.4 14.4004 18.4"
      stroke="#514F4F"
      stroke-width="1.2"
    />
  </svg>
</template>
