<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6768 13.1375L16.2775 7.16871L11.6768 1.19993"
      stroke="#514F4F"
      stroke-width="2"
    />
    <path d="M16.2774 7.42361L3 7.42361" stroke="#514F4F" stroke-width="2" />
    <path
      d="M5.94727 11.9437L1.34652 17.9125L5.94727 23.8813"
      stroke="#514F4F"
      stroke-width="2"
    />
    <path
      d="M1.34665 18.2113L14.624 18.2113"
      stroke="#514F4F"
      stroke-width="2"
    />
  </svg>
</template>
<script>
export default {
  name: "StExchange",
};
</script>
