<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4238 8.6585C13.7537 8.28148 13.7537 7.71852 13.4238 7.3415L8.75258 2.00294C8.14454 1.30804 7 1.73808 7 2.66145L7 13.3386C7 14.2619 8.14454 14.692 8.75258 13.9971L13.4238 8.6585Z"
      fill="#2B241D"
    />
  </svg>
</template>
