<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    role="img"
    aria-labelledby="childTitle"
  >
    <title id="childTitle">Ett barn</title>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M10.249 18.93c-.071-.994.036-2.109.093-3.314.012-.259.176-.81-.14-.887-.51-.124-.394 1.45-.42 2.007-.041.862-.092 1.883-.28 2.242h-.607c-1.309-.312-.806-1.903-.56-3.269.143-.798.223-1.606.42-2.24.266-.86 1.383-1.659 2.147-1.728.448-.041.94.135 1.401.093.272-.025.536-.148.747-.14.704.027 1.54.63 1.914 1.12.526.687.606 1.995.794 3.035.212 1.174.806 2.994-.654 3.129h-.513c-.02-.042-.041-.084-.094-.094-.1-.66-.133-1.356-.186-2.194-.03-.458.11-1.997-.374-1.961-.461.034-.169 1.599-.14 2.007.06.837.136 1.515.047 2.195-.054-.008-.083.01-.094.047h-3.408c-.001-.046-.057-.037-.093-.047zm0 0zm1.4-14.566h.84c1.592.307 2.66 1.138 3.036 2.661 1.157.311 2.512 1.105 2.474 2.661-.02.87-.593 1.84-1.68 1.728-.042-1.203-.186-2.305-.748-2.988-.38 1.747-2.018 2.978-4.015 2.754-1.672-.187-2.637-1.403-3.081-2.894-.582.74-.717 1.929-.84 3.128-1.064.085-1.63-.886-1.635-1.774-.007-1.579 1.404-2.348 2.615-2.662.4-1.482 1.443-2.323 3.034-2.614z"
      />
    </g>
  </svg>
</template>
