<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8.50049L9.71141 1.07766C9.83245 0.83558 10.1763 0.831057 10.3037 1.06987L14 8.00049"
      stroke="black"
      stroke-width="2"
    />
    <path
      d="M18.656 8.00073L16.256 16.0007H3.74403L1.34403 8.00073H10L18.656 8.00073Z"
      stroke="black"
      stroke-width="2"
    />
    <circle cx="10" cy="12.0007" r="2" fill="black" />
  </svg>
</template>
