<template>
  <svg
    width="49"
    height="46"
    viewBox="0 0 49 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.2436 5.67917C28.9109 6.6514 28.7303 7.694 28.7303 8.77852C28.7303 14.0647 33.0206 18.355 38.3068 18.355C38.6939 18.355 39.0756 18.332 39.4506 18.2873C39.7456 19.5928 39.9013 20.9509 39.9013 22.3452C39.9013 32.4771 31.6783 40.7001 21.5464 40.7001C11.4144 40.7001 3.19141 32.4771 3.19141 22.3452C3.19141 12.2133 11.4144 3.99023 21.5464 3.99023C24.2946 3.99023 26.9023 4.59522 29.2436 5.67917ZM27.1323 15.9608C27.1323 12.8697 24.6371 10.3745 21.546 10.3745C18.4549 10.3745 15.9597 12.8697 15.9597 15.9608C15.9597 19.0519 18.4549 21.5471 21.546 21.5471C24.6371 21.5471 27.1323 19.0519 27.1323 15.9608ZM21.9232 36.0058C17.0918 36.0058 12.9391 33.7168 9.57711 30.2474C9.63508 26.6886 18.0581 24.7393 21.9232 24.7393C25.7689 24.7393 33.4604 26.6886 33.5183 30.2474C31.0254 33.7168 26.7545 36.0058 21.9232 36.0058Z"
      :fill="primaryColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.3257 8.77827C30.3257 4.37308 33.9009 0.797852 38.3061 0.797852C42.7113 0.797852 46.2865 4.37308 46.2865 8.77827C46.2865 13.1835 42.7113 16.7587 38.3061 16.7587C33.9009 16.7587 30.3257 13.1835 30.3257 8.77827ZM39.2635 9.73598H43.0941V7.82068H39.2635V3.99008H37.3482V7.82068H33.5176V9.73598H37.3482V13.5666H39.2635V9.73598Z"
      :fill="secondaryColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    primaryColor: {
      type: String,
      default: "#6E6054",
    },
    secondaryColor: {
      type: String,
      default: "#6d202b",
    },
  },
};
</script>
