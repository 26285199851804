<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95468 6.48533C5.22396 6.17881 5.53813 6.02554 5.91962 6.02554C6.09914 6.02554 6.25622 6.07663 6.43575 6.15326C6.61527 6.22989 6.74991 6.35761 6.86211 6.51087L12.562 13.1011L18.217 6.45979C18.3292 6.33207 18.4862 6.20435 18.6658 6.12772C18.8453 6.05109 19.0248 6 19.1819 6C19.5634 6 19.9 6.15326 20.1468 6.45979C20.3937 6.76631 20.5508 7.12392 20.5508 7.55816C20.5508 7.76251 20.5059 7.94132 20.4385 8.14567C20.3712 8.35002 20.2815 8.50328 20.1693 8.631L13.8635 16.0131C13.7064 16.2175 13.5045 16.3707 13.2801 16.4729C13.0557 16.5751 12.8088 16.6262 12.562 16.6262C12.2927 16.6262 12.0683 16.5751 11.8439 16.4729C11.6195 16.3707 11.44 16.2175 11.2829 16.0131L4.95468 8.68208C4.84248 8.55437 4.73028 8.37556 4.66295 8.19675C4.59563 8.01795 4.55075 7.8136 4.55075 7.60925C4.55075 7.17501 4.68539 6.79185 4.95468 6.51087V6.48533Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "ot-down",
  props: {
    color: {
      type: String,
      default: "#E10E1C",
    },
  },
};
</script>
