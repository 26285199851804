<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h22v22H0z" />
      <path
        fill="#DF0000"
        d="M11 21s7.602-7.1 7.499-12.5c-.08-4.198-3.36-7.5-7.5-7.5C6.857 1 3.494 4.3 3.5 8.5 3.509 13.9 11 21 11 21z"
      />
    </g>
  </svg>
</template>
