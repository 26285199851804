<template>
  <label
    :class="classList"
    :tabindex="disabled ? '-1' : '0'"
    @keydown.space="handleChange"
    @click="handleChange"
    role="radio"
    :aria-checked="isChecked"
    :aria-required="required"
  >
    <input
      class="st-radio-button__input"
      type="radio"
      :required="required"
      :disabled="disabled"
      :checked="isChecked"
      tabindex="-1"
    />
    <span class="st-radio-button__checkmark"></span>
    <span class="st-radio-button__text"><slot /></span>
  </label>
</template>

<script>
export default {
  name: "st-radio-button",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: "0",
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    value: {
      default: "",
    },
  },
  computed: {
    isChecked: {
      get() {
        return this.modelValue === this.value;
      },
      set() {
        this.$emit("update:modelValue", this.value);
      },
    },
    classList() {
      const radioButton = "st-radio-button";
      return [radioButton, this.disabled && `${radioButton}--is-disabled`];
    },
  },
  methods: {
    handleChange() {
      this.isChecked = !this.isChecked;
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style lang="scss">
@import "../../scss/main.scss";
.st-radio-button {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: rem-calc(16);
  color: var(--text-color-primary);
  font-weight: 600;
  font-family: var(--font-primary), sans-serif;
  box-sizing: initial;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__text {
    color: var(--text-color-primary);
    font-weight: 600;
    left: rem-calc(35);
    position: absolute;
  }
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: rem-calc(20);
    width: rem-calc(20);
    background-color: var(--background-color-secondary);
    border-radius: 50%;
    border: rem-calc(2) solid var(--interactive-color-primary);
    &::after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  // Checkbox disabled
  &--is-disabled {
    cursor: default;
  }
  &--is-disabled &__checkmark {
    border-color: var(--border-color-tertiary);
  }
  &--is-disabled &__text {
    color: var(--text-color-quaternary);
  }
  &:hover &__input:disabled ~ &__checkmark {
    border-color: var(--border-color-tertiary);
  }
  &:focus &__input:disabled ~ &__checkmark {
    box-shadow: none;
  }
  // Radiobutton when hovered (not selected)
  &:hover &__input ~ &__checkmark {
    border-color: var(--interactive-color-secondary);
  }
  // Radiobutton when focused
  &:focus &__input ~ &__checkmark {
    box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
      0 0 0 rem-calc(4) var(--border-color-quaternary);
  }
  &:hover &__input ~ &__checkmark {
    &::after {
      top: rem-calc(4);
      left: rem-calc(4);
      width: rem-calc(12);
      height: rem-calc(12);
      border-radius: 50%;
      background: var(--interactive-color-secondary);
    }
  }
  // Radiobutton when selected
  & &__input:checked ~ &__checkmark {
    background-color: var(--background-color-secondary);
    &::after {
      display: block;
    }
  }
  & &__checkmark {
    &::after {
      top: rem-calc(4);
      left: rem-calc(4);
      width: rem-calc(12);
      height: rem-calc(12);
      border-radius: 50%;
      background: var(--interactive-color-primary);
    }
  }
  &:focus {
    outline: none;
  }
}
</style>
