<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#6E6054"
        d="M18.029 10.506l-5.158 5.78a1 1 0 0 1-1.472.023l-5.48-5.78a1 1 0 0 1 .726-1.689h10.638a1 1 0 0 1 .746 1.666z"
      />
    </g>
  </svg>
</template>
