<template>
  <button
    class="st-back-button"
    tabindex="0"
    @click="useRoute ? $router.push(route) : $router.go(-1)"
  >
    <arrow-left-icon /><span class="st-back-button__text">{{ text }}</span>
  </button>
</template>

<script>
import ArrowLeftIcon from "../../icons/st/arrow-left-icon";

export default {
  name: "st-back-button",
  props: {
    route: String,
    useRoute: {
      default: true,
      type: Boolean,
    },
    text: {
      type: String,
      default: "Tillbaka",
    },
  },
  components: {
    ArrowLeftIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-back-button {
  all: unset;
  background-color: var(--background-color-secondary);
  border-radius: rem-calc(20);
  box-shadow: 0 rem-calc(2) rem-calc(4) 0 rgba(0, 0, 0, 0.1);
  display: inline-flex;
  padding: rem-calc(2) rem-calc(12);
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  &:focus {
    outline: rem-calc(1) solid var(--border-color-quaternary);
  }
  &:hover {
    box-shadow: 0 rem-calc(5) rem-calc(5) 0 rgba(0, 0, 0, 0.15);
  }
  &__text {
    margin-left: rem-calc(4);
    font-family: var(--font-primary);
    font-size: rem-calc(14);
    line-height: 150%;
    &:hover {
      color: var(--interactive-color-secondary);
    }
  }
}
</style>
