<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="23"
    viewBox="0 0 25 23"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        :fill="color"
        d="M13.76 0v23L0 20.575V2.34L13.76 0zM9.684 7.159l-1.558.099-1.246 2.774L5.854 7.5H4.24l1.613 3.806-1.796 3.892h1.647L6.8 12.436l1.143 2.916 1.84.102-2.028-4.22 1.93-4.075z"
      />
      <path
        :stroke="color"
        stroke-width="1.3"
        d="M14.5 3h8.586c.505 0 .914.421.914.941V18.06c0 .52-.41.941-.914.941H14.5"
      />
      <path
        :stroke="color"
        stroke-width=".7"
        d="M18.5 3.214V19M24.5 6.5h-10M24.5 9.5h-10M24.5 12.5h-10M24.5 15.5h-10"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6d202b",
    },
  },
};
</script>
