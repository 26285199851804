<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 0H9C7.35 0 6 1.35 6 3V29C6 30.65 7.35 32 9 32H23C24.65 32 26 30.65 26 29V3C26 1.35 24.65 0 23 0ZM12 1.5H20V2.5H12V1.5ZM16 30C14.895 30 14 29.105 14 28C14 26.895 14.895 26 16 26C17.105 26 18 26.895 18 28C18 29.105 17.105 30 16 30ZM24 24H8V4H24V24Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "bt-mobile",
  props: {
    color: {
      type: String,
      default: "#E0213B",
    },
  },
};
</script>
