<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M12.667 12.667H3.333V3.333H8V2H3.333C2.593 2 2 2.6 2 3.333v9.334C2 13.4 2.593 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V8h-1.333v4.667zM9.333 2v1.333h2.394L5.643 9.417a.665.665 0 0 0 .94.94l6.084-6.084v2.394H14V2.5a.5.5 0 0 0-.5-.5H9.333z"
      />
    </g>
  </svg>
</template>
