<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M-3-3h22v22H-3z" />
      <path
        fill="#514F4F"
        d="M8.892 0C9.504 0 10 .49 10 1.102V17H0V1.102C0 .494.496 0 1.108 0h7.784zm-.158 1H1.266A.274.274 0 0 0 1 1.273V16h8V1.273A.275.275 0 0 0 8.734 1z"
      />
      <g stroke="#514F4F" stroke-width=".55">
        <path
          d="M2.225 2.775h1.65v1.65h-1.65zM5.525 2.775h1.65v1.65h-1.65zM2.225 6.075h1.65v1.65h-1.65zM2.225 9.375h1.65v1.65h-1.65zM2.225 12.675h1.65v1.65h-1.65zM5.525 6.075h1.65v1.65h-1.65zM5.525 9.375h1.65v1.65h-1.65zM5.525 12.675h1.65v1.65h-1.65z"
        />
      </g>
      <g>
        <path
          fill="#514F4F"
          d="M15.904 4A1.1 1.1 0 0 1 17 5.108V17H9V5.108C9 4.496 9.5 4 10.096 4h5.808zm-.177 1h-5.454a.275.275 0 0 0-.273.272V16h6V5.272A.274.274 0 0 0 15.727 5z"
        />
        <path
          stroke="#514F4F"
          stroke-width=".55"
          d="M11.575 9.375h1.65v1.65h-1.65zM11.575 6.075h1.65v1.65h-1.65zM11.575 12.675h1.65v1.65h-1.65z"
        />
      </g>
    </g>
  </svg>
</template>
