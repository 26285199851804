<template>
  <div class="st-autocomplete-input">
    <label
      v-if="!labelInsideInput"
      :id="id"
      :class="'st-autocomplete-input__label-outside'"
      :for="id + 'AutocompleteCombobox'"
    >
      {{ label }}
    </label>
    <div
      :class="[
        'st-autocomplete-input-container',
        { 'st-autocomplete-input-container--has-focus': state.hasFocus },
        { 'st-autocomplete-input-container--label-inside': labelInsideInput },
        { 'st-autocomplete-input-container--is-expanded': suggestionsVisible },
      ]"
    >
      <label
        v-if="labelInsideInput"
        class="st-autocomplete-input-container__label-inside"
        :id="id"
        :for="id + 'AutocompleteCombobox'"
      >
        {{ label }}
      </label>
      <div
        :class="[
          'st-autocomplete-input-container__input',
          {
            'st-autocomplete-input-container__input--has-focus': state.hasFocus,
          },
        ]"
      >
        <StInput
          aria-autocomplete="list"
          :aria-controls="id + 'AutocompleteListbox'"
          role="combobox"
          :aria-activedescendant="ariaActivedescendant"
          :placeholder="placeholder"
          :id="id + 'AutocompleteCombobox'"
          :focus="onFocus"
          :blur="onBlur"
          v-model="inputValue"
          @update:model-value="handleUserInput"
          @keydown.esc="onEsc"
          @keydown.enter="onEnter"
          @keydown.down.prevent="onArrowKeyDown"
          @keydown.up.prevent="onArrowKeyUp"
          @keydown.delete="$emit('keydownDelete')"
          :aria-expanded="suggestionsVisible ? 'true' : 'false'"
        />
      </div>
    </div>
    <ul
      class="st-autocomplete-input__suggestions"
      :id="id + 'AutocompleteListbox'"
      role="listbox"
      :aria-labelledby="id"
      v-show="suggestionsVisible"
      @mousedown.prevent
    >
      <li
        v-if="showListHeadline"
        role="presentation"
        class="st-autocomplete-input__suggestions__headline"
      >
        <slot name="list-headline"></slot>
      </li>
      <li
        v-for="(item, index) in autocompleteSuggestions"
        @click="selectItem(item)"
        :key="item.id"
        role="option"
        :id="id + 'Option-' + index"
        :aria-selected="index === state.focusIndex"
        :class="[
          'st-autocomplete-input__suggestions__item',
          {
            'st-autocomplete-input__suggestions__item--focused':
              index === state.focusIndex,
          },
        ]"
      >
        <slot name="suggestion-item" :item="item">{{ item.value }}</slot>
      </li>
    </ul>
  </div>
</template>

<script setup>
import StInput from "../input/Input.vue";
import { debounce } from "lodash";
import {
  defineProps,
  defineEmits,
  reactive,
  computed,
  watch,
  onMounted,
  defineModel,
} from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
    required: true,
  },
  id: {
    type: String,
    default: "",
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocompleteSuggestions: {
    validator: (value) => {
      return value.every(
        (item) =>
          Object.prototype.hasOwnProperty.call(item, "id") &&
          Object.prototype.hasOwnProperty.call(item, "value")
      );
    },
    default: () => [],
  },
  showListHeadline: {
    type: Boolean,
  },
  labelInsideInput: {
    type: Boolean,
  },
});

const inputValue = defineModel("inputValue", { required: true });
const selectedItem = defineModel("selectedItem", { required: true });

const state = reactive({
  focusIndex: null,
  showSuggestions: false,
  hasFocus: false,
  isTypedInputChange: false,
});

const emit = defineEmits([
  "input",
  "esc",
  "select",
  "blur",
  "suggestionsVisible",
  "keydownDelete",
]);

const ariaActivedescendant = computed(() =>
  state.focusIndex !== null ? props.id + "Option-" + state.focusIndex : null
);

const suggestionsVisible = computed(
  () => state.showSuggestions && props.autocompleteSuggestions.length > 0
);

const debounceInput = debounce((val) => {
  handleInput(val);
}, 200);

const handleUserInput = () => {
  state.isTypedInputChange = true;
};

const onFocus = () => {
  state.showSuggestions = true;
  state.focusIndex = null;
  state.hasFocus = true;
  emit("input", inputValue.value);
};

const handleInput = (val) => {
  state.focusIndex = null;
  emit("input", val);
};

const onArrowKeyUp = () => {
  if (state.focusIndex > 0) {
    state.focusIndex--;
  } else {
    state.focusIndex = props.autocompleteSuggestions.length - 1;
  }
};

const onArrowKeyDown = () => {
  if (
    state.focusIndex === null ||
    state.focusIndex >= props.autocompleteSuggestions.length - 1
  ) {
    state.focusIndex = 0;
  } else {
    state.focusIndex++;
  }
};

const onBlur = () => {
  state.isTypedInputChange = false;
  state.showSuggestions = false;
  state.hasFocus = false;
  state.focusIndex = null;
  emit("blur");
};

const onEnter = () => {
  const focusedItem = props.autocompleteSuggestions[state.focusIndex];
  if (focusedItem) {
    selectItem(focusedItem);
  }
};

const onEsc = () => {
  inputValue.value = "";
  state.showSuggestions = false;
  emit("esc");
};

const selectItem = (item) => {
  state.isTypedInputChange = false;
  selectedItem.value = item;
  inputValue.value = item?.value || "";
  state.showSuggestions = false;
  emit("select", item);
};

onMounted(() => {
  if (selectedItem.value) {
    selectItem(selectedItem.value);
  }
});

watch(
  () => selectedItem.value,
  (newValue) => {
    selectItem(newValue);
  }
);

watch(
  () => inputValue.value,
  (newVal) => {
    if (state.isTypedInputChange) {
      state.showSuggestions = true;
      debounceInput(newVal);
    } else {
      handleInput(newVal);
    }
  }
);

watch(suggestionsVisible, (bool) => {
  emit("suggestionsVisible", bool);
});
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-autocomplete-input {
  width: 100%;
  position: relative;

  &__label-outside {
    font-size: rem-calc(14);
    font-weight: 600;
    color: var(--text-color-primary);
    line-height: 1.4;
    display: inline-block;
    margin-bottom: rem-calc(4);
    text-align: left;
  }

  &-container {
    display: flex;
    border-radius: var(--border-radius);
    border: var(--border-width--thin) solid var(--text-color-secondary);

    &__input {
      flex-grow: 1;
    }

    &--has-focus {
      border: var(--text-color-secondary) solid rem-calc(2);
    }

    &--is-expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    :deep(.st-input__container, .st-input__container:focus-within) {
      border: none;
      box-shadow: none;
    }

    &--label-inside {
      :deep(.st-input__container) {
        border-left: var(--border-width--thin) solid
          var(--text-color-quaternary) !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &__label-inside {
      font-size: rem-calc(14);
      width: rem-calc(48);
      margin-bottom: 0;
      color: var(--text-color-primary);
      align-self: center;
      text-align: center;
    }
  }
  &__suggestions {
    font-size: rem-calc(14);
    list-style: none;
    position: absolute;
    background-color: var(--background-color-secondary);
    width: inherit;
    z-index: 3;
    margin: 0;
    padding: 0;
    border: var(--border-width--thin) solid var(--text-color-secondary);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    overflow: hidden;
    border-top: none;
    box-sizing: border-box;

    &__headline,
    &__item {
      padding: var(--md);
      font-weight: var(--font-weight-semi-bold);
      color: var(--text-color-primary);
      &:not(:last-child) {
        border-bottom: var(--border-width--thin) solid
          var(--text-color-quaternary);
      }
    }
    &__item {
      color: var(--text-color-primary);
      &--focused,
      &:hover {
        background-color: var(--text-color-secondary);
        color: #fff;
        cursor: pointer;
      }
      &__type {
        font-size: rem-calc(14);
        font-weight: var(--font-weight-regular);
      }
    }
  }
}
</style>
