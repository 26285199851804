<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.1542 18.2397L34.1626 16.2003L32.4504 15.797L32.38 16.2882C32.38 16.2882 32.3687 16.3683 32.3574 16.482L18.1609 20.641L6.1215 16.6138C5.94408 16.3631 5.45124 15.5308 5.42308 14.0471C5.39774 12.7857 5.85396 12.4109 6.05673 12.3075L17.8426 17.0714L34.2612 12.8659L34.3288 10.7903L22.5288 7.19995L6.54957 9.71498C5.547 9.82871 3.6376 10.8342 3.60099 13.8093C3.56719 16.4588 4.38953 17.8235 4.83449 18.3663L4.07129 18.599L4.13325 20.6746L20.442 24.8516L20.5518 24.8801L32.3405 20.1163C32.5433 20.2197 32.9995 20.5945 32.9742 21.8558C32.946 23.3421 32.4504 24.1718 32.2758 24.4226L20.2392 28.4497L6.19473 24.3321L6.04547 24.2907C6.0342 24.1744 6.02294 24.0969 6.02294 24.0969L5.95253 23.6058L4.23745 24.0116L4.2459 26.051L19.7239 31.2L33.1741 26.5344L33.2896 26.4697C33.3516 26.418 34.8413 25.1722 34.7991 21.6232C34.7822 20.0672 34.2471 19.0591 33.6022 18.4258L34.1542 18.2397Z"
      fill="#6E6055"
    />
  </svg>
</template>
