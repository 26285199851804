<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="16"
    viewBox="0 0 24 16"
  >
    <g fill="none" fill-rule="evenodd">
      <rect
        width="22.262"
        height="14.989"
        x=".505"
        y=".505"
        fill="#FFF"
        stroke="#6E6054"
        stroke-width="1.011"
        rx="2.021"
      />
      <path
        fill="#6E6054"
        d="M.233 10.5h22.261v1H.233zM3.763 4h8.095v1H3.763zM3.763 6.5h9.613v1H3.763z"
      />
    </g>
  </svg>
</template>
