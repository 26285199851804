<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h22v22H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M11 1.833A9.167 9.167 0 1 0 20.167 11c0-5.063-4.104-9.167-9.167-9.167zM7.724 14.042a.69.69 0 1 1-1.38 0V8.246h-.31a.589.589 0 0 1-.582-.583c0-.318.265-.584.583-.584h1.03c.383 0 .658.318.658.69v6.273h.001zm6.047.499a.666.666 0 0 1-.986-.022l-1.464-1.59v1.102c0 .393-.328.7-.679.7a.684.684 0 0 1-.69-.7V8.368c0-.382.297-.679.69-.679a.69.69 0 0 1 .679.68v2.884l1.358-1.633c.233-.266.679-.308.987-.085a.731.731 0 0 1 .085.965l-1.326 1.58 1.377 1.485c.266.286.224.71-.031.976zm2.396-.5a.695.695 0 0 1-1.388 0V8.369c0-.382.318-.69.699-.69.37 0 .69.308.69.69v5.674z"
      />
    </g>
  </svg>
</template>
