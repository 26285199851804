<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Färja</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <path
          d="M2,18.1590293 C2,18.1590293 3.31588567,18.9969459 5.41650552,18.9963922 C7.51712537,18.9958385 7.77533713,18.1330158 10.2276673,18.1568606 C12.6799974,18.1807054 12.8313625,19.0631855 14.8708777,18.9963922 C16.9103929,18.9295989 18,18 18,18"
          stroke="#514F4F"
          stroke-width="1.5"
        ></path>
        <path
          d="M14.9941413,4 C16.1019465,4 17,4.90400515 17,6.00824652 L17,16.277832 C12.9293327,17.6479126 13.0436792,16.0766748 10,16.0733425 C6.95632076,16.0700102 6.96111512,17.3107484 3,16.4412231 L3,5.99264479 C3,4.89213746 3.89706013,4 5.00585866,4 L14.9941413,4 Z M15,6.040926 L5,6.040926 L5,13 L15,13 L15,6.040926 Z"
          fill="#514F4F"
        ></path>
        <path
          d="M10.0415043,9.02717598 L18.906,11.706176 L19,11.7068426 C19,11.7068426 18.9990139,11.7150895 18.9970851,11.7308265 L19.0197697,11.7405979 L18.9962377,11.7377278 C18.9558888,12.0657645 18.5966052,14.9083428 18.1902637,15.5113048 C17.7588768,16.1514312 16.9105587,16.3003742 16.9105587,16.3003742 C16.9105587,16.3003742 11.9517064,15.2408246 10.0143674,15.2408246 C8.06221257,15.2408246 3.01144052,16.4225947 3.01144052,16.4225947 C3.01144052,16.4225947 2.49690386,16.3205433 1.8720268,15.5113048 C1.24714974,14.7020662 1,11.7068426 1,11.7068426 L1.558,11.706176 L1.04409331,11.7052051 L10.0415043,9.02717598 Z M13,11 C12.4477153,11 12,11.4477153 12,12 C12,12.5522847 12.4477153,13 13,13 C13.5522847,13 14,12.5522847 14,12 C14,11.4477153 13.5522847,11 13,11 Z"
          fill="#514F4F"
        ></path>
        <rect fill="#514F4F" x="5" y="3" width="10" height="2"></rect>
        <rect fill="#514F4F" x="7" y="1" width="6" height="3"></rect>
      </g>
    </g>
  </svg>
</template>
