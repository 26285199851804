<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM7.2024 11.2L12.8584 5.5432L11.7272 4.412L7.2024 8.9376L4.9392 6.6744L3.808 7.8056L7.2024 11.2Z"
      fill="#509E2F"
    />
  </svg>
</template>
