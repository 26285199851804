<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h18v17H0z" />
      <path
        fill="#6E6054"
        d="M13 1H5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1zm-1 1H6h6zM16 3H2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 1H3h12zM2 4h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm6.253 6.411l.163-.614c.185-.666.369-1.444.523-2.15h.04c.185.696.359 1.484.543 2.15l.164.614H8.253zm2.15 2.765H12L9.901 6.5H8.099L6 13.176h1.536l.41-1.587h2.047l.41 1.587z"
      />
    </g>
  </svg>
</template>
