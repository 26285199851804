<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 34 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,-184.398,-275.495)">
      <g
        id="Favourite--small-"
        serif:id="Favourite (small)"
        transform="matrix(1,3.69779e-32,0,1,54.3977,74.4949)"
      >
        <path
          d="M153.7,221L163,214L150.9,214L147,202L143.1,214L131,214L140.3,221L137,233L147,225.2L157,233L153.7,221Z"
          style="
            fill-opacity: 0;
            fill-rule: nonzero;
            stroke: rgb(247, 187, 11);
            stroke-width: 2px;
          "
        />
      </g>
    </g>
  </svg>
</template>
