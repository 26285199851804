<template>
  <ul class="st-list">
    <st-list-item
      v-for="(item, index) in list"
      :key="`${item.label}-${index}`"
      :item="item"
      :skip-border-bottom="skipBorderBottom"
      :is-vertical="isVertical"
    />
  </ul>
</template>

<script>
import StListItem from "./ListItem.vue";
export default {
  name: "st-list",
  components: {
    StListItem,
  },
  props: {
    list: [Object, Array],
    skipBorderBottom: Boolean,
    isVertical: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.st-list {
  width: 100%;
}
</style>
