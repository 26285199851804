<template>
  <div
    class="st-article"
    :class="(isCard ? 'st-article--card ' : 'st-article--block ') + environment"
  >
    <div class="st-article__inner">
      <div
        v-if="hasVideoLink()"
        class="st-article__inner__img st-article__inner__img--with-video"
      >
        <img :src="item.imageUrl" :alt="item.imageAlt" />
        <div
          class="st-article__inner__img__btn"
          @click="openVideoModal(videoLink)"
          @keydown.enter="openVideoModal(videoLink)"
          role="button"
          aria-label="Spela upp film."
          tabindex="0"
        >
          <play-big-icon class="play-button" />
        </div>
      </div>
      <div v-else class="st-article__inner__img">
        <img :src="item.imageUrl" :alt="item.imageAlt" />
      </div>
      <div class="st-article__inner__text">
        <h2 class="st-article__inner__text__headline">{{ item.title }}</h2>
        <p class="st-article__inner__text__paragraph" v-html="item.text"></p>
        <div class="st-article__inner__text__links" v-if="item.links">
          <template v-for="link in item.links">
            <st-button
              v-if="link.href && link.href.isVideo"
              @click="openVideoModal(link.href.value)"
              :key="link.href.value"
              tabindex="0"
            >
              {{ link.text ? link.text : defaultLinkText }}
            </st-button>
            <st-link
              v-else-if="link.href"
              :link="link.href.value"
              :isExternal="link.href.isExternal"
              :key="link.href.value"
            >
              {{ link.text ? link.text : defaultLinkText }}
            </st-link>
          </template>
          <transition name="video-fade-transition">
            <st-modal
              :width="56.5"
              class="st-article__modal"
              large
              v-if="showModal"
              @esc-close="() => (showModal = false)"
            >
              <template #body>
                <div class="st-article__modal__body">
                  <div
                    class="st-article__modal__body__icon"
                    @click="showModal = false"
                  >
                    <ic-close-big-icon class="close-button" />
                  </div>
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      class="embed-responsive-item"
                      :src="videoLink"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </template>
            </st-modal>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StLink from "../link/Link.vue";
import StButton from "../button/Button.vue";
import PlayBigIcon from "../../icons/st/play-big.vue";
import StModal from "../modal/Modal.vue";
import IcCloseBigIcon from "../../icons/st/ic-close-big.vue";
export default {
  name: "st-article",
  components: {
    StLink,
    StButton,
    PlayBigIcon,
    StModal,
    IcCloseBigIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        id: "",
        imageUrl: "",
        text: "",
        title: "",
        links: [
          {
            text: "",
            href: {
              value: "",
              isVideo: false,
              isExternal: false,
            },
          },
        ],
      }),
    },
    isCard: {
      type: Boolean,
      default: false,
    },
    defaultLinkText: {
      type: String,
      default: "Mer information",
    },
    environment: {
      type: String,
      default: "st",
    },
  },
  data() {
    return {
      videoLink: undefined,
      showModal: false,
    };
  },
  methods: {
    // Returns true if at least one of the links for an article is a video link
    hasVideoLink() {
      let hasVideo = false;
      this.item.links.forEach((link) => {
        if (link.href && link.href.isVideo) {
          this.videoLink = link.href.value;
          hasVideo = true;
        }
      });
      return hasVideo;
    },
    openVideoModal(link) {
      this.videoLink = link;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/main.scss";

.st-article {
  background-color: var(--background-color-secondary);
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: rem-calc(320);
    padding: var(--xxl) var(--xl);
    &__text {
      &__headline {
        font-family: var(--font-secondary);
        margin-bottom: 0;
        color: var(--text-color-primary);
        font-weight: 700;
        font-size: rem-calc(26);
        margin-top: var(--xl);
      }
      &__links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: var(--xl);
        & > *:first-child {
          margin-right: var(--xs);
        }
      }
      &__paragraph {
        font-family: var(--font-primary);
        color: var(--text-color-primary);
        font-size: rem-calc(16);
        text-align: left;
        margin: 0;
        margin-top: var(--xl);
        font-weight: normal;
        line-height: rem-calc(24);
        @include large {
          font-size: rem-calc(18);
        }
        & > a {
          color: inherit;
          &:focus {
            border: rem-calc(2) solid var(--border-color-quaternary);
            outline: none;
          }
        }
      }
    }
    &__img {
      &--with-video {
        position: relative;
      }
      img {
        width: 100%;
        border-radius: rem-calc(16);
      }
      &__btn:focus {
        .play-button {
          border: var(--border-width) var(--border-color-quaternary) solid;
        }
      }
      .play-button {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem-calc(80);
        height: rem-calc(80);
        background-color: rgba(0, 0, 0, 0.24);
        border-radius: rem-calc(50);
        &:hover {
          box-shadow: 0 rem-calc(2) rem-calc(4) 0 rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
  &:nth-child(even) {
    &.ot {
      background-color: #f1f4f8;
    }
    &.st,
    &.bt {
      background-color: var(--background-color-tertiary);
    }
  }
  @include large {
    &--block {
      .st-article__inner {
        flex-direction: row;
        max-width: rem-calc(1050);
        &__text {
          width: 50%;
          margin-left: var(--xl);
          &__headline {
            font-size: rem-calc(34);
            margin: 0;
          }
        }
        &__img {
          width: 50%;
          margin-right: var(--xl);
        }
      }
      &:nth-child(odd) {
        .st-article__inner {
          &__text {
            order: 1;
            margin-right: var(--xl);
            margin-left: 0;
          }
          &__img {
            order: 2;
            margin-left: var(--xl);
            margin-right: 0;
          }
        }
      }
    }
    &--card {
      margin: var(--md);
      border-radius: rem-calc(16);
      .st-article__inner {
        height: 100%;
        width: rem-calc(370);
        padding: 0;
        &__img {
          img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: rem-calc(220);
          }
        }
        &__text {
          height: 100%;
          display: flex;
          flex-direction: column;
          padding: 0 rem-calc(32) rem-calc(32) rem-calc(32);
          &__paragraph {
            flex-grow: 1;
          }
        }
      }
      &:nth-child(even) {
        &.ot,
        &.st,
        &.bt {
          background-color: var(--background-color-secondary);
        }
      }
    }
  }
  &__modal {
    &__body {
      &__icon {
        display: inline-flex;
        cursor: pointer;
      }
    }
  }
}
.video-fade-transition-enter-active,
.video-fade-transition-leave-active {
  transition: opacity 0.25s;
}
.video-fade-transition-enter,
.video-fade-transition-leave-to {
  opacity: 0;
}
</style>
