<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5692 14.3738C12.439 14.504 12.2279 14.504 12.0978 14.3738L9.55731 11.8334C9.42713 11.7032 9.42713 11.4921 9.55731 11.362L10.0287 10.8905C10.1589 10.7604 10.3699 10.7604 10.5001 10.8905L13.0406 13.431C13.1707 13.5612 13.1707 13.7722 13.0406 13.9024L12.5692 14.3738Z"
      fill="#2B241D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6668 11.2239C12.6668 11.408 12.5176 11.5572 12.3335 11.5572L10.5573 11.5572C10.3732 11.5572 10.224 11.7065 10.224 11.8906L10.224 13.6667C10.224 13.8508 10.0747 14 9.89062 14H9.22396C9.03986 14 8.89063 13.8508 8.89063 13.6667L8.89063 10.5572C8.89063 10.3731 9.03986 10.2239 9.22396 10.2239L12.3335 10.2239C12.5176 10.2239 12.6668 10.3731 12.6668 10.5572L12.6668 11.2239Z"
      fill="#2B241D"
    />
    <path d="M12.6668 5.33333H9.3335V2L12.6668 5.33333Z" fill="#2B241D" />
    <path
      d="M7.3335 14H3.46683C3.39319 14 3.3335 13.9403 3.3335 13.8667V2.13333C3.3335 2.0597 3.39319 2 3.46683 2H9.3335M12.6668 8.66667V5.33333M12.6668 5.33333H9.3335V2M12.6668 5.33333L9.3335 2"
      stroke="#2B241D"
      stroke-width="1.33333"
      stroke-linecap="round"
    />
  </svg>
</template>
