<template>
  <svg
    width="20"
    height="28"
    viewBox="0 0 20 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33317 3.33335V24.6667H16.6665V3.33335H3.33317ZM1.99984 0.666687H17.9998C18.3535 0.666687 18.6926 0.807163 18.9426 1.05721C19.1927 1.30726 19.3332 1.6464 19.3332 2.00002V26C19.3332 26.3536 19.1927 26.6928 18.9426 26.9428C18.6926 27.1929 18.3535 27.3334 17.9998 27.3334H1.99984C1.64622 27.3334 1.30708 27.1929 1.05703 26.9428C0.80698 26.6928 0.666504 26.3536 0.666504 26V2.00002C0.666504 1.6464 0.80698 1.30726 1.05703 1.05721C1.30708 0.807163 1.64622 0.666687 1.99984 0.666687ZM9.99984 20.6667C10.3535 20.6667 10.6926 20.8072 10.9426 21.0572C11.1927 21.3073 11.3332 21.6464 11.3332 22C11.3332 22.3536 11.1927 22.6928 10.9426 22.9428C10.6926 23.1929 10.3535 23.3334 9.99984 23.3334C9.64622 23.3334 9.30708 23.1929 9.05703 22.9428C8.80698 22.6928 8.6665 22.3536 8.6665 22C8.6665 21.6464 8.80698 21.3073 9.05703 21.0572C9.30708 20.8072 9.64622 20.6667 9.99984 20.6667Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#5C5957",
    },
  },
};
</script>
