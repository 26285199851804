<template>
  <div :class="classList">
    <slot name="label">
      <label class="st-form-group__label" v-if="label" :for="forId">
        {{ label }}
      </label>
    </slot>
    <slot name="description">
      <p
        v-if="description"
        :id="descriptionId"
        class="st-form-group__description"
      >
        {{ description }}
      </p>
    </slot>
    <slot>default slot</slot>
    <span
      v-for="error in validationErrors"
      :key="error.key"
      class="st-form-group__error-wrapper"
    >
      <transition name="fade">
        <span
          v-if="validation[error.key].$invalid"
          :id="error.id ? `${error.id}-error` : `${forId}-error`"
          class="st-form-group__error-wrapper__error"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <st-icon
            name="st-varning"
            class="st-form-group__error-wrapper__error__warning"
          />
          {{ error.message }}
        </span>
      </transition>
    </span>
  </div>
</template>

<script>
import { computed } from "vue";
import StIcon from "../icon/Icon.vue";

export default {
  name: "st-form-group",
  components: {
    StIcon,
  },
  props: {
    forId: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    validationErrors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    description: {
      type: String,
    },
    descriptionId: {
      type: String,
    },
  },
  setup(props) {
    const classList = computed(() => {
      const formGroup = "st-form-group";
      return [
        formGroup,
        props.required && `${formGroup}--requried`,
        hasError.value && `${formGroup}--invalid`,
      ];
    });

    const hasError = computed(() => {
      if (!props.validation) return false;

      return props.validation.$error;
    });
    return { classList, hasError };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-form-group {
  &--requried {
    label:after {
      content: " - obligatoriskt";
      font-style: italic;
      font-weight: 400;
    }
  }

  &--invalid {
    .st-form-group__error-wrapper__error {
      display: flex;
      color: var(--danger-color-primary);
      align-items: center;
    }
  }

  &__label {
    font-size: rem-calc(14);
    font-weight: 600;
    font-family: var(--font-primary);
    color: var(--text-color-primary);
    display: inline-block;
    margin-bottom: rem-calc(4);
  }

  &__description {
    font-family: var(--font-primary);
    font-size: rem-calc(14);
    color: var(--text-color-primary);
    line-height: rem-calc(21);
    margin: 0 0 rem-calc(4) 0;
  }

  &__error-wrapper {
    &__error {
      display: none;
      font-size: rem-calc(14);
      font-weight: 600;
      font-family: var(--font-primary);
      margin-top: rem-calc(4);

      &__warning {
        background-size: rem-calc(14) rem-calc(14);
        display: inline-block;
        margin-right: rem-calc(6);
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s linear;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
