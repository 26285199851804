<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0449 19.9067C15.1986 19.753 15.5054 19.4462 15.1986 18.9857C14.7381 18.3714 13.049 16.9899 9.67165 16.8361C9.36487 16.8361 8.59687 16.8361 7.82956 16.6824C7.06226 16.6824 6.60104 16.6824 8.90435 14.9933C9.21113 14.6865 9.36487 14.5328 9.67165 14.3791C9.82539 14.2253 10.5927 13.3043 8.75061 12.8438C6.75478 12.537 4.9127 12.69 2.45635 13.3043C1.84209 13.458 1.07478 13.6111 0.460522 13.7648L0 19.138C0 19.9053 0.460522 20.5196 1.22852 20.5196L10.4397 21.2869C12.1287 21.1332 13.6633 20.8264 15.0456 19.9053L15.0449 19.9067ZM30.8577 3.32585L2.91617 0.869507C2.30191 0.869507 1.53461 1.33003 1.53461 2.09803L0.613565 11.6166C3.22365 10.6956 5.98678 10.3881 7.82887 10.5419C10.8995 10.6956 12.1273 11.0024 12.5878 12.2309C12.8946 13.3057 12.4341 14.2267 11.8205 14.994C13.8163 15.6083 15.9659 16.3756 16.5795 18.6789C16.7332 19.5999 16.7332 21.1352 15.8122 21.9025L15.6584 22.0563L29.1687 23.2848C29.936 23.2848 30.5503 22.8243 30.5503 22.0563L32.0856 5.0149C32.2393 4.09385 31.6257 3.47959 30.8577 3.32585Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "bt-travel-card",
  props: {
    color: {
      type: String,
      default: "#E0213B",
    },
  },
};
</script>
