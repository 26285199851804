<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_961_14426"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_961_14426)">
      <path
        d="M10 10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9.5C9.36667 7.5 9.25 7.55 9.15 7.65C9.05 7.75 9 7.86667 9 8V12C9 12.1333 9.05 12.25 9.15 12.35C9.25 12.45 9.36667 12.5 9.5 12.5C9.63333 12.5 9.75 12.45 9.85 12.35C9.95 12.25 10 12.1333 10 12V10.5ZM10 9.5V8.5H11V9.5H10ZM15 12.5C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13.5C13.3667 7.5 13.25 7.55 13.15 7.65C13.05 7.75 13 7.86667 13 8V12C13 12.1333 13.05 12.25 13.15 12.35C13.25 12.45 13.3667 12.5 13.5 12.5H15ZM14 11.5V8.5H15V11.5H14ZM18 10.5H18.5C18.6333 10.5 18.75 10.45 18.85 10.35C18.95 10.25 19 10.1333 19 10C19 9.86667 18.95 9.75 18.85 9.65C18.75 9.55 18.6333 9.5 18.5 9.5H18V8.5H18.5C18.6333 8.5 18.75 8.45 18.85 8.35C18.95 8.25 19 8.13333 19 8C19 7.86667 18.95 7.75 18.85 7.65C18.75 7.55 18.6333 7.5 18.5 7.5H17.5C17.3667 7.5 17.25 7.55 17.15 7.65C17.05 7.75 17 7.86667 17 8V12C17 12.1333 17.05 12.25 17.15 12.35C17.25 12.45 17.3667 12.5 17.5 12.5C17.6333 12.5 17.75 12.45 17.85 12.35C17.95 12.25 18 12.1333 18 12V10.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V7C2 6.71667 2.09583 6.47917 2.2875 6.2875C2.47917 6.09583 2.71667 6 3 6C3.28333 6 3.52083 6.09583 3.7125 6.2875C3.90417 6.47917 4 6.71667 4 7V20H17C17.2833 20 17.5208 20.0958 17.7125 20.2875C17.9042 20.4792 18 20.7167 18 21C18 21.2833 17.9042 21.5208 17.7125 21.7125C17.5208 21.9042 17.2833 22 17 22H4Z"
        :fill="color"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6D202B",
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
