<template>
  <div :class="classList" v-no-focus:[isLoading]="isLoading">
    <st-breadcrumb :items="breadCrumbItems" v-if="breadCrumbItems" />
    <div class="st-card__wrapper">
      <div
        class="st-card__wrapper__spinner"
        v-if="isLoading"
        :aria-busy="isLoading"
        aria-live="polite"
      >
        <st-spinner
          :loadingMessage="loadingMessage"
          :ariaLabel="spinnerAriaLabel"
        ></st-spinner>
      </div>
      <div class="st-card__wrapper__slot">
        <slot>default slot</slot>
      </div>
    </div>
  </div>
</template>

<script>
import StSpinner from "../spinner/Spinner.vue";
import StBreadcrumb from "../breadcrumb/Breadcrumb";
import { noFocus } from "../../helpers/no-focus";
export default {
  name: "st-card",
  directives: {
    "no-focus": noFocus,
  },
  components: {
    StSpinner,
    StBreadcrumb,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: undefined,
    },
    spinnerAriaLabel: {
      type: String,
      default: "",
    },
    breadCrumbItems: {
      type: Array,
    },
    hideOverflow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classList: function () {
      return [
        "st-card",
        this.isLoading && "loading",
        this.hideOverflow && "st-card--is-overflow-hidden",
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-card {
  font-family: var(--font-primary);
  border-radius: var(--xl);
  background-color: var(--background-color-secondary);
  box-shadow: 0 1em 1.5em 0 rgba(43, 36, 29, 0.08);

  &__wrapper {
    position: relative;
    &__spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(106, 106, 106, 0.203);
      backdrop-filter: blur(rem-calc(5));
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    padding: rem-calc(45) rem-calc(22);
    @include large {
      padding: rem-calc(65) rem-calc(65);
    }
  }

  &--is-overflow-hidden {
    overflow: hidden;
  }
}
</style>
