<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 22 22"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h22v22H0z" />
      <path
        :fill="color"
        fill-rule="nonzero"
        d="M4.583 2.75H10.5a.917.917 0 0 1 0 1.833H4.583v12.834H10.5a.917.917 0 1 1 0 1.833H4.583a1.839 1.839 0 0 1-1.833-1.833V4.583c0-1.008.825-1.833 1.833-1.833zm10.312 12.187a.916.916 0 0 1 0-1.294l1.72-1.726H8.666a.917.917 0 0 1 0-1.834h7.947l-1.72-1.726a.916.916 0 0 1 1.294-1.294l3.583 3.583a.5.5 0 0 1 0 .708l-3.583 3.583a.914.914 0 0 1-1.293 0z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6E6054",
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
