export const noFocus = {
  mounted(el, value) {
    const handleKeyDown = () => {
      if (value.value) {
        const focusableElements = el.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        focusableElements.forEach((el) => {
          el.setAttribute("tabindex", "-1");
        });
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    el.addEventListener("beforeUnmount", () => {
      document.removeEventListener("keydown", handleKeyDown);
    });
  },
  updated(el, value) {
    if (value.value) {
      const focusableElements = el.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      focusableElements.forEach((el) => {
        el.setAttribute("tabindex", "-1");
      });
    }
  },
};
