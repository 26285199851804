<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M24 24H0V0h24z" />
      <path
        stroke="#6E6054"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M16.692 13.914l-4.778-4.778-4.778 4.778"
      />
    </g>
  </svg>
</template>
