<template>
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42 22C30.9543 22 22 30.9543 22 42C22 53.0457 30.9543 62 42 62C53.0457 62 62 53.0457 62 42C62 30.9543 53.0457 22 42 22ZM14 42C14 26.536 26.536 14 42 14C57.464 14 70 26.536 70 42C70 48.2893 67.9264 54.0944 64.4255 58.7687L80.8284 75.1716C82.3905 76.7337 82.3905 79.2663 80.8284 80.8284C79.2663 82.3905 76.7337 82.3905 75.1716 80.8284L58.7687 64.4255C54.0944 67.9264 48.2893 70 42 70C26.536 70 14 57.464 14 42Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#5C5957",
    },
  },
};
</script>
