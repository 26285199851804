<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    role="img"
    aria-labelledby="studentTitle"
  >
    <title id="studentTitle">En student</title>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g fill="#6E6054" fill-rule="nonzero" transform="translate(5 3)">
        <circle cx="8.326" cy="3.06" r="2.696" />
        <path
          d="M13.534 13.67l-.851-4.812c-.202-1.146-1.162-2.093-2.456-2.563a3.665 3.665 0 0 0-.27-.094 1.128 1.128 0 0 0-.668.005 3.276 3.276 0 0 1-1.87.014 1.134 1.134 0 0 0-.665.001c-.07.023-.14.047-.207.074-1.294.47-2.254 1.417-2.456 2.563L4 9.397l1.303-.224a.716.716 0 0 1 .345.026l3.285 1.09c.3.099.498.382.489.698l-.019.63a.713.713 0 0 1-.224.498c.124.13.197.306.197.495l-.003.618c0 .03-.002.06-.005.088.105.242.18.547.185.928.01.829-.197 1.359-.388 1.67h1.282c.198 0 .355-.155.345-.343-.1-1.697-.277-4.767-.29-5.022a.339.339 0 0 1 .33-.347c.173-.01.355.132.364.314.014.257.164 3.38.293 5.065.025.334.282.334.282.334h.128c.589 0 1.034-.167 1.325-.493.343-.386.448-.975.311-1.752z"
        />
        <path
          d="M8.66 13.225l.002-.618-.477-.123-.02.15-.006.058-3.952 1.262-3.352-1.222c-.05-.076-.187-.328-.195-.779-.007-.382.12-.496.177-.527l3.281 1.445 4.571-1.276.02-.63-3.286-1.089-4.45.763c-.278.035-.81.34-.82 1.242-.01.804.22 1.218.343 1.383l-.212.07.017.63 4.54 1.267.031.009 3.283-1.445c.056.03.183.145.176.527-.008.451-.146.703-.194.779l-3.352 1.221-3.91-1.249-.042-.012-.006-.059-.02-.149-.477.123.002.619 4.31 1.562 3.745-1.416.032-.02c.017-.015.432-.393.42-1.47-.005-.471-.154-.777-.333-.97l.153-.056z"
        />
      </g>
    </g>
  </svg>
</template>
