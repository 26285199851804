<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0002 53.6663C12.2722 53.6663 0.333496 41.7277 0.333496 26.9997C0.333496 12.2717 12.2722 0.333008 27.0002 0.333008C41.7282 0.333008 53.6668 12.2717 53.6668 26.9997C53.6668 41.7277 41.7282 53.6663 27.0002 53.6663ZM24.3415 37.6663L43.1948 18.8103L39.4242 15.0397L24.3415 30.125L16.7975 22.581L13.0268 26.3517L24.3415 37.6663Z"
      fill="#3EA827"
    />
  </svg>
</template>
