<template>
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    role="img"
    aria-labelledby="seniorTitle"
  >
    <!-- Generator: Sketch 58 (84663) - https://sketch.com -->
    <title id="seniorTitle">En senior</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <rect x="0" y="0" width="40" height="40"></rect>
      </g>
      <g transform="translate(7.000000, 8.500000)">
        <path
          d="M16.8646812,9.39742219 L17.0411383,9.45982437 C18.9487318,10.1168079 20.3629623,11.4417431 20.6599045,13.0441687 L20.6599045,13.0441687 L21.9146874,19.775234 C22.1157299,20.8621634 21.960904,21.6869575 21.4559868,22.2243898 C21.0273271,22.6817556 20.3710502,22.9142774 19.503332,22.9142774 L19.503332,22.9142774 L19.3075209,22.914007 C19.2542113,22.9110327 18.9329411,22.8753409 18.8990489,22.4470403 C18.7095605,20.0911126 18.4877204,15.721459 18.4680783,15.3628052 C18.4542133,15.1072507 18.1861566,14.9098266 17.9308094,14.9229882 C17.6488877,14.9361498 17.4305138,15.1533163 17.4443788,15.4088708 C17.4640209,15.765331 17.7263006,20.0604022 17.8718831,22.4338787 C17.8869036,22.696014 17.6558202,22.9142774 17.3646551,22.9142774 L17.3646551,22.9142774 L11.2836952,22.9142774 C10.9925301,22.9142774 10.7602913,22.6971109 10.7764671,22.4338787 C10.9220497,20.0593054 11.1843293,15.765331 11.2039714,15.4088708 C11.2178364,15.1533163 11.000618,14.9361498 10.7175409,14.9229882 C10.46566,14.9109234 10.1941369,15.1061539 10.1802719,15.3628052 C10.1617853,15.6951358 9.74930139,22.4437499 9.74930139,22.4437499 C9.72734847,22.8210493 8.24158263,23.1654655 8.24158263,22.9142774 C8.24158263,22.6630892 8.11910843,20.6705703 8.24158263,20.0804916 C8.31198105,16.1950328 8.2314535,13.8495919 8,13.0441687 C8.24588691,11.604199 9.53739298,10.2877398 11.2721423,9.58818397 C11.9291394,10.3016787 12.8709819,10.7476807 13.985759,10.7476807 C15.1977984,10.7476807 16.2054102,10.220458 16.8646812,9.39742219 Z"
          fill="#514F4F"
          fill-rule="nonzero"
        ></path>
        <circle
          fill="#514F4F"
          fill-rule="nonzero"
          cx="14"
          cy="7"
          r="3"
        ></circle>
        <path
          d="M7,21.7077637 C7,22.2600484 6.55228475,22.7077637 6,22.7077637 C5.44771525,22.7077637 5,22.2600484 5,21.7077637 L5,14.853804 C5,13.1171254 4.53998098,12.5 3.5,12.5 C2.46001902,12.5 2,13.1171254 2,14.853804 C2,15.4060887 1.55228475,15.853804 1,15.853804 C0.44771525,15.853804 0,15.4060887 0,14.853804 C0,12.1187439 1.20664764,10.5 3.5,10.5 C5.79335236,10.5 7,12.1187439 7,14.853804 L7,21.7077637 Z"
          fill="#514F4F"
          fill-rule="nonzero"
        ></path>
        <g transform="translate(7.300000, 0.200451)">
          <path
            d="M8.83822056,0.799549065 L9.58236725,4.40154906 L10.2397525,4.44245735 C11.4564103,4.60636968 12.223213,4.70193708 12.223213,4.97619258 C12.223213,5.46973695 9.70651279,6.31775083 6.60229013,6.31775083 C3.4962996,6.31775083 0.981367246,5.46973695 0.981367246,4.97619258 C0.981367246,4.70193708 1.74676469,4.60651875 2.96339985,4.44260641 L3.61806832,4.33490946 L3.63236725,4.33654906 L4.3663597,0.799549065 C5.55885593,0.799549065 5.85697999,1.39579718 6.60083678,1.39579718 C7.34760028,1.39579718 7.64572434,0.799549065 8.83822056,0.799549065 Z"
            fill="#514F4F"
          ></path>
          <path
            d="M3.8135024,3.67724754 C4.18289923,3.7453987 6.50434037,3.8983365 9.43427201,3.59683605 L9.59846319,4.368885 C6.5345899,4.80528637 3.4975539,4.368885 3.65174573,4.368885 L3.8135024,3.67724754 Z"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
