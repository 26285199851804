<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0013 27.3337C6.6373 27.3337 0.667969 21.3643 0.667969 14.0003C0.667969 6.63633 6.6373 0.666992 14.0013 0.666992C21.3653 0.666992 27.3346 6.63633 27.3346 14.0003C27.3346 21.3643 21.3653 27.3337 14.0013 27.3337ZM14.0013 24.667C16.8303 24.667 19.5434 23.5432 21.5438 21.5428C23.5442 19.5424 24.668 16.8293 24.668 14.0003C24.668 11.1713 23.5442 8.45824 21.5438 6.45785C19.5434 4.45747 16.8303 3.33366 14.0013 3.33366C11.1723 3.33366 8.45922 4.45747 6.45883 6.45785C4.45844 8.45824 3.33464 11.1713 3.33464 14.0003C3.33464 16.8293 4.45844 19.5424 6.45883 21.5428C8.45922 23.5432 11.1723 24.667 14.0013 24.667V24.667ZM12.672 19.3337L7.01464 13.6763L8.89997 11.791L12.672 15.563L20.2133 8.02033L22.1 9.90566L12.672 19.3337Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6D202B",
    },
  },
};
</script>
