<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99979 5V1C4.99979 0.44772 5.4475 0 5.99979 0H18.9998C19.5521 0 19.9998 0.44772 19.9998 1V14C19.9998 14.5523 19.5521 15 18.9998 15H15V18.9925C15 19.5489 14.551 20 13.9925 20H1.00728C0.45086 20 0 19.5511 0 18.9925L0.00275993 6.00748C0.00287993 5.45107 0.4518 5 1.01025 5H4.99979ZM6.99979 5H13.9927C14.549 5 15 5.44892 15 6.00748V13H17.9998V2H6.99979V5ZM6.50242 16L12.1593 10.3431L10.7451 8.9289L6.50242 13.1716L4.3811 11.0503L2.96689 12.4645L6.50242 16Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6D202B",
    },
  },
};
</script>
