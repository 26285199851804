<template>
  <div>
    <div v-if="rotation === 'right'">
      <svg
        width="4"
        height="13"
        viewBox="0 0 4 13"
        :fill="color"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.30184 7.2171L3.95578 11.1608C4.06055 11.7977 3.97987 12.2809 3.71673 12.5958C3.49333 12.8638 3.15131 13 2.69909 13H2.60094C2.60094 13 2.40344 13 2.38417 12.7262C2.28542 11.3459 2.1698 8.78572 2.15957 8.57559C2.15234 8.42586 2.01264 8.31019 1.87957 8.3179C1.73264 8.32561 1.61884 8.45285 1.62606 8.60258C1.6363 8.81143 1.77299 11.3279 1.84886 12.7185C1.85669 12.8721 1.73626 13 1.58451 13H-4.19617e-05V5.19364C0.257077 5.19364 0.462108 5.12024 0.695742 5.04377C0.862537 4.9885 1.04198 4.984 1.20877 5.03927C1.27862 5.0624 1.34787 5.08811 1.41591 5.11702C2.41006 5.50195 3.14709 6.27823 3.30184 7.2171Z"
          :fill="color"
        />
        <path
          d="M2.00038 2.49707C2.00038 3.60164 1.10495 4.49707 0.000380427 4.49707C0 4 -3.8147e-06 3.60457 -3.8147e-06 2.5C-3.8147e-06 1.39543 -3.8147e-06 1.5 0.000380427 0.49707C1.10495 0.49707 2.00038 1.3925 2.00038 2.49707Z"
          :fill="color"
        />
      </svg>
    </div>
    <div v-else>
      <svg
        width="4"
        height="13"
        viewBox="0 0 4 13"
        :fill="color"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.698354 7.2171L0.0444193 11.1608C-0.0603547 11.7977 0.0203333 12.2809 0.283473 12.5958C0.50687 12.8638 0.848892 13 1.30111 13H1.39926C1.39926 13 1.59676 13 1.61603 12.7262C1.71478 11.3459 1.8304 8.78572 1.84063 8.57559C1.84786 8.42586 1.98756 8.31019 2.12063 8.3179C2.26756 8.32561 2.38136 8.45285 2.37414 8.60258C2.3639 8.81143 2.22721 11.3279 2.15134 12.7185C2.14351 12.8721 2.26394 13 2.41568 13H4.00024V5.19364C3.74312 5.19364 3.53809 5.12024 3.30446 5.04377C3.13766 4.9885 2.95822 4.984 2.79143 5.03927C2.72158 5.0624 2.65233 5.08811 2.58429 5.11702C1.59014 5.50195 0.853106 6.27823 0.698354 7.2171Z"
          :fill="color"
        />
        <path
          d="M1.99982 2.49707C1.99982 3.60164 2.89525 4.49707 3.99982 4.49707C4.0002 4 4.0002 3.60457 4.0002 2.5C4.0002 1.39543 4.0002 1.5 3.99982 0.49707C2.89525 0.49707 1.99982 1.3925 1.99982 2.49707Z"
          :fill="color"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "OccupancyHuman",
  props: { color: String, rotation: String },
};
</script>
