<template>
  <td class="st-table-item__data-cell">
    <span class="st-table-item__data-cell__mobile-header">
      {{ header.name }}
    </span>
    <div
      v-if="data.component"
      @click="data.clickable ? handleItemClick($event) : () => {}"
      @keydown.enter="data.clickable ? handleItemClick($event) : () => {}"
    >
      <component :is="data.component" v-bind="data.componentProps" />
    </div>
    <span v-else class="st-table-item__data-cell__span"> {{ data.value }}</span>
  </td>
</template>

<script>
export default {
  props: {
    data: Object,
    header: Object,
  },
  setup(_, { emit }) {
    const handleItemClick = (e) => {
      emit("on-item-click");
      e.stopPropagation();
    };

    return {
      handleItemClick,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-table-item__data-cell {
  padding: rem-calc(18) rem-calc(10);
  display: flex;
  flex-direction: row;
  & > * {
    width: 50%;
  }
  @include large {
    &:first-child {
      border-left: rem-calc(3) solid var(--border-color-primary);
    }
  }
  &__span {
    color: var(--text-color-primary);
    font-size: rem-calc(16);
  }
  @include large {
    & > * {
      width: 100%;
    }
    display: table-cell;
    padding: rem-calc(22);
  }
  &__mobile-header {
    display: table-header-group;
    font-weight: var(--font-weight-semi-bold);
    padding-right: rem-calc(10);
    @include large {
      display: none;
    }
  }
}
</style>
