<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C23.9928 5.37555 18.6244 0.00716485 12 0ZM22 12.0022C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.57111 6.32611 2.15265 11.719 2.00387L11.9989 2C17.52 2.00656 21.994 6.48096 22 12.0022ZM12.25 5C13.0784 5 13.75 5.67157 13.75 6.5C13.75 7.32843 13.0784 8 12.25 8C11.4216 8 10.75 7.32843 10.75 6.5C10.75 5.67157 11.4216 5 12.25 5ZM14.5 18.5H10.5C9.94771 18.5 9.5 18.0523 9.5 17.5C9.5 16.9477 9.94771 16.5 10.5 16.5H11.25C11.3881 16.5 11.5 16.3881 11.5 16.25V11.75C11.5 11.6119 11.3881 11.5 11.25 11.5H10.5C9.94771 11.5 9.5 11.0523 9.5 10.5C9.5 9.94772 9.94771 9.5 10.5 9.5H11.5C12.6046 9.5 13.5 10.3954 13.5 11.5V16.25C13.5 16.3881 13.6119 16.5 13.75 16.5H14.5C15.0523 16.5 15.5 16.9477 15.5 17.5C15.5 18.0523 15.0523 18.5 14.5 18.5Z"
      :fill="color"
      fill-opacity="0.87"
    />
  </svg>
</template>
<script>
export default {
  name: "bt-info",
  props: {
    color: {
      type: String,
      default: "#000",
    },
  },
};
</script>
