<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        fill-rule="nonzero"
        :stroke="color"
        stroke-width="1.5"
        d="M14.924 2.417H5.076a2.66 2.66 0 0 0-2.66 2.66v9.847a2.66 2.66 0 0 0 2.66 2.66h9.848a2.66 2.66 0 0 0 2.66-2.66V5.076a2.66 2.66 0 0 0-2.66-2.66z"
      />
      <path
        :fill="color"
        d="M11.474 15.183H9.01a.86.86 0 0 1 0-1.72h.395V9.448H9.01a.86.86 0 0 1 0-1.72h1.256a.86.86 0 0 1 .86.86v4.874h.348a.86.86 0 0 1 0 1.721zM10.176 4.298a1.134 1.134 0 1 1 0 2.267 1.134 1.134 0 0 1 0-2.267z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "st-info",
  props: {
    color: {
      type: String,
      default: "#6E6054",
    },
  },
};
</script>
