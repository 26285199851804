<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    title="gång"
  >
    <g fill="#030303" fill-opacity=".7" transform="translate(4 1)">
      <ellipse
        cx="4.006"
        cy="1.459"
        rx="1.294"
        ry="1.213"
        transform="rotate(-87.272 4.006 1.459)"
      />
      <path
        d="M6.646 7.732L5.18 6.187c-.126-.12-.2-.362-.189-.616l.084-1.8c.013-.432-.32-.812-.738-.833l-.827-.048c-.292-.015-.546.15-.69.398-.01.01-.02.017-.028.029L1.538 5.253c-.096.14-.155.306-.172.48l-.21 2.274c-.02.217.13.411.333.432.013.002.025.002.037.002.188 0 .35-.152.368-.356l.21-2.274c.002-.033.014-.064.038-.1l.492-.76-.078 1.756L2.243 10c-.007.117-.036.23-.084.335L.91 13.032c-.135.292-.072.655.169.855.332.273.794.145.97-.234L3.1 11.38c.257-.556.41-1.16.449-1.78l.03-.49 1.426 4.51c.09.284.339.463.605.463.068 0 .137-.011.205-.036.334-.12.514-.507.4-.864L4.52 7.605c-.025-.081-.035-.167-.03-.252l.046-.758c.043.06.085.12.135.168l1.454 1.532c.072.076.167.114.26.114.096 0 .192-.039.265-.118.144-.155.142-.405-.004-.559z"
      />
    </g>
  </svg>
</template>
