<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h22v22H0z" />
      <path
        fill="#6E6054"
        fill-rule="nonzero"
        d="M10.083 16.5h1.834v-1.833h-1.834V16.5zM11 1.833C5.94 1.833 1.833 5.94 1.833 11S5.94 20.167 11 20.167 20.167 16.06 20.167 11 16.06 1.833 11 1.833zm0 16.5c-4.043 0-7.333-3.29-7.333-7.333S6.957 3.667 11 3.667s7.333 3.29 7.333 7.333-3.29 7.333-7.333 7.333zM11 5.5a3.666 3.666 0 0 0-3.667 3.667h1.834c0-1.009.825-1.834 1.833-1.834s1.833.825 1.833 1.834c0 1.833-2.75 1.604-2.75 4.583h1.834c0-2.063 2.75-2.292 2.75-4.583A3.666 3.666 0 0 0 11 5.5z"
      />
    </g>
  </svg>
</template>
