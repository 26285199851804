<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="26"
    viewBox="0 0 39 26"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#6E6054"
        d="M17.77 8.875c0 6.072 5.098 11 11.381 11a11.7 11.7 0 0 0 3.958-.684v4A2.809 2.809 0 0 1 30.302 26H2.81A2.811 2.811 0 0 1 0 23.191V6.81A2.809 2.809 0 0 1 2.809 4h16.138a10.648 10.648 0 0 0-1.177 4.875z"
      />
      <path
        fill="#FFF"
        d="M.72 18.438h31.67v1.375H.72zM4.319 9.5h11.516v1.375H4.319zM4.319 12.938h13.676v1.375H4.319z"
      />
      <path d="M18.624 0h17.59v17h-17.59z" />
      <g fill="#F6A200" fill-rule="nonzero">
        <path
          d="M28.971 0c5.14 0 9.312 4.032 9.312 9s-4.172 9-9.312 9-9.312-4.032-9.312-9 4.172-9 9.312-9zm.852 6.463l-5.313 5.15v1.936h1.992l5.313-5.15-1.992-1.936zm1.679-1.751l-1.05 1.02 2.152 2.092 1.05-1.02a.544.544 0 0 0 0-.787l-1.343-1.305a.583.583 0 0 0-.809 0z"
        />
      </g>
    </g>
  </svg>
</template>
