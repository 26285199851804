<template>
  <svg
    width="32"
    height="25"
    viewBox="0 0 32 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.0497 2.89362H31.0238C31.0238 2.89362 31.1015 3.02321 31.1015 3.10097C31.1015 3.17872 31.1015 3.23056 31.1015 3.30831V20.7511C31.1015 21.6841 30.7646 22.4876 30.1166 23.1614C29.4687 23.8353 28.6652 24.1722 27.7063 24.1722H3.39525C2.43628 24.1722 1.63283 23.8353 0.984881 23.1614C0.336933 22.4876 0 21.6841 0 20.7511V3.59341C0 2.66036 0.336933 1.85691 0.984881 1.18304C1.63283 0.509174 2.43628 0.172241 3.39525 0.172241H27.7063C28.5097 0.172241 29.2354 0.43142 29.8575 0.949779C30.4795 1.46814 30.8682 2.11609 31.0497 2.86771V2.89362ZM3.39525 2.60853C3.11015 2.60853 2.87689 2.7122 2.66955 2.89362C2.4622 3.07505 2.38445 3.33423 2.38445 3.61933V7.55885H28.6911V3.61933C28.6911 3.33423 28.5875 3.10097 28.406 2.89362C28.2246 2.68628 27.9654 2.60853 27.6803 2.60853H3.39525ZM27.7063 21.736C27.9914 21.736 28.2246 21.6323 28.432 21.4509C28.6393 21.2694 28.7171 21.0103 28.7171 20.7252V9.9433H2.41037V20.7252C2.41037 21.0103 2.51404 21.2435 2.69546 21.4509C2.87689 21.6582 3.13607 21.736 3.42117 21.736H27.7322H27.7063ZM14.8251 12.0427H14.8769C15.1879 12.0427 15.473 12.1463 15.7063 12.3796C15.9395 12.6128 16.0432 12.8979 16.0432 13.209C16.0432 13.52 15.9395 13.8051 15.7063 14.0383C15.473 14.2716 15.1879 14.3753 14.8769 14.3753H5.98704C5.65011 14.3753 5.36501 14.2716 5.13175 14.0383C4.89849 13.8051 4.79482 13.52 4.79482 13.209C4.79482 12.8979 4.89849 12.6128 5.13175 12.3796C5.36501 12.1463 5.65011 12.0427 5.98704 12.0427H14.8251ZM10.419 16.9411C10.73 16.9411 11.0151 17.0448 11.2484 17.2781C11.4816 17.5113 11.5853 17.7964 11.5853 18.1074C11.5853 18.4185 11.4816 18.7036 11.2484 18.9368C11.0151 19.1701 10.73 19.2738 10.419 19.2738H6.01296C5.70194 19.2738 5.41685 19.1701 5.18359 18.9368C4.95032 18.7036 4.84665 18.4185 4.84665 18.1074C4.84665 17.7964 4.95032 17.5113 5.18359 17.2781C5.41685 17.0448 5.70194 16.9411 6.01296 16.9411H10.419Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "ot-travel-card",
  props: {
    color: {
      type: String,
      default: "#E10E1C",
    },
  },
};
</script>
