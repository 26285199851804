<template>
  <div class="st-travel-sausage">
    <travel-sausage-badge :color="color" :traffic-type="trafficType" />
    <span>
      <!-- need to add a '.' after 'm' for walk, otherwise screen reader doesn't understand the abbreviation -->
      {{ text }}{{ trafficType === "walk" ? "." : "" }}
    </span>
  </div>
</template>

<script>
import TravelSausageBadge from "./TravelSausageBadge.vue";
export default {
  name: "st-travel-sausage",
  props: {
    trafficType: String,
    text: String,
    color: String,
  },
  components: {
    TravelSausageBadge,
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

@mixin three20 {
  @media (max-width: 320px) {
    @content;
  }
}

.st-travel-sausage {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--background-color-tertiary);
  border-radius: rem-calc(4);
  font-family: var(--font-primary);
  font-weight: var(--font-weight-regular);
  font-size: rem-calc(16);
  line-height: rem-calc(24);
  color: var(--text-color-primary);
  padding-right: rem-calc(4);
  @include large {
    font-size: rem-calc(18);
  }

  @include three20 {
    font-size: rem-calc(13);
  }

  & > *:not(:last-child) {
    margin-right: rem-calc(8);
  }
}
</style>
