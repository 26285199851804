<template>
  <div class="st-travel-sausage-group">
    <st-travel-sausage
      v-for="(item, index) in list"
      :key="`${item.text}-${index}`"
      v-bind="item"
    />
  </div>
</template>

<script>
import StTravelSausage from "./TravelSausage.vue";

export default {
  name: "st-travel-sausage-group",
  components: {
    StTravelSausage,
  },
  props: {
    list: {
      type: Array,
    },
  },
};
</script>

<style lang="scss">
.st-travel-sausage-group {
  display: flex;
}
</style>
