<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 22 22"
    role="img"
    aria-labelledby="playBigTitle"
  >
    <title id="playBigTitle">Spela upp video</title>
    <path
      fill="#6E6054"
      fill-rule="nonzero"
      d="M11 20.5a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19zm0 1.5C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11zm3.992-10.582l-5.468 3.576a.5.5 0 0 1-.774-.418V7.424a.5.5 0 0 1 .774-.418l5.468 3.576a.5.5 0 0 1 0 .836z"
    />
  </svg>
</template>
