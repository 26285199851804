<template>
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.325 24.5C15.525 24.5 14.025 23.825 12.825 22.475C11.625 21.125 11.15 19.55 11.4 17.75L12.375 10.4C12.775 7.55 14.075 5.188 16.275 3.314C18.475 1.438 21.05 0.5 24 0.5C26.95 0.5 29.525 1.438 31.725 3.314C33.925 5.188 35.225 7.55 35.625 10.4L36.6 17.75C36.85 19.55 36.375 21.125 35.175 22.475C33.975 23.825 32.475 24.5 30.675 24.5H17.325ZM0 48.5V40.1C0 38.4 0.438001 36.837 1.314 35.411C2.188 33.987 3.35 32.9 4.8 32.15C7.9 30.6 11.05 29.437 14.25 28.661C17.45 27.887 20.7 27.5 24 27.5C27.3 27.5 30.55 27.887 33.75 28.661C36.95 29.437 40.1 30.6 43.2 32.15C44.65 32.9 45.812 33.987 46.686 35.411C47.562 36.837 48 38.4 48 40.1V48.5H0Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "st-avatar-bust",
  props: {
    color: {
      type: String,
      default: "#5C5957",
    },
  },
};
</script>
