<template>
  <div class="st-travel-sausage-badge">
    <svg
      width="41"
      height="24"
      viewBox="0 0 41 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
    >
      <path
        d="M37.5102 24H2.8841C1.29307 24 0 22.7042 0 21.1099V2.89014C0 1.29577 1.29307 0 2.8841 0H29.1446C34.3394 0 40.1076 17.3521 40.1076 21.1099V21.3972C40.1076 22.9972 39.1069 24 37.5102 24Z"
        :fill="color"
      />
    </svg>

    <div class="st-travel-sausage-badge__icon">
      <component :is="icon" :aria-label="ariaLabel" />
    </div>
  </div>
</template>
<script>
import CallTrafficIcon from "../../icons/st/call-traffic.vue";
import WalkIcon from "../../icons/st/walk.vue";
import BusIcon from "../../icons/st/bus.vue";
import TrainIcon from "../../icons/st/train.vue";
import TramIcon from "../../icons/st/tram.vue";
import FerryIcon from "../../icons/st/ferry.vue";
export default {
  components: {
    BusIcon,
    TrainIcon,
    CallTrafficIcon,
    WalkIcon,
  },
  props: {
    trafficType: String,
    color: String,
  },
  computed: {
    icon() {
      const badgeIcon = {
        bus: BusIcon,
        train: TrainIcon,
        ondemand: CallTrafficIcon,
        calltraffic: CallTrafficIcon,
        metro: TramIcon,
        tram: TramIcon,
        walk: WalkIcon,
        ferry: FerryIcon,
      };
      return badgeIcon[this.trafficType];
    },
    ariaLabel() {
      const badgeIcon = {
        bus: "Buss",
        train: "Tåg",
        ondemand: "Anropsstyrd trafik",
        calltraffic: "Anropsstyrd trafik",
        metro: "Metro",
        walk: "Gång",
        ferry: "Färja",
      };
      return badgeIcon[this.trafficType];
    },
  },
};
</script>
<style lang="scss">
.st-travel-sausage-badge {
  display: inline-flex;
  position: relative;
  &__icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-45%, -50%);
  }
}
</style>
