<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 27.3337C6.63616 27.3337 0.666626 21.3641 0.666626 14.0003C0.666626 6.63653 6.63616 0.666992 14 0.666992C21.3637 0.666992 27.3333 6.63653 27.3333 14.0003C27.3333 21.3641 21.3637 27.3337 14 27.3337ZM12.6701 19.3337L22.0982 9.90557L20.2126 8.01995L12.6701 15.5625L8.89891 11.7911L7.01328 13.6769L12.6701 19.3337Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6D202B",
    },
  },
};
</script>
