<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="25"
    viewBox="0 0 35 25"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#6E6054"
        d="M19.478 7.125c0 4.554 3.74 8.25 8.348 8.25 1.52 0 2.946-.402 4.175-1.105L32 22.19A2.807 2.807 0 0 1 29.188 25H2.812A2.813 2.813 0 0 1 0 22.191V5.81A2.807 2.807 0 0 1 2.812 3h17.785a8.134 8.134 0 0 0-1.119 4.125z"
      />
      <path
        fill="#FFF"
        d="M.696 17.438h30.609v1.375H.696zM4.174 8.5h11.13v1.375H4.174zM4.174 11.938h13.217v1.375H4.174z"
      />
      <path d="M18-1h17v17H18z" />
      <path
        fill="#F6A200"
        fill-rule="nonzero"
        d="M28 0c3.864 0 7 3.136 7 7s-3.136 7-7 7-7-3.136-7-7 3.136-7 7-7zm.7 3.5h-1.4v2.8h-2.8v1.4h2.8v2.8h1.4V7.7h2.8V6.3h-2.8V3.5z"
      />
    </g>
  </svg>
</template>
