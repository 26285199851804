<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12.0001 4.17391C14.0209 4.17391 15.6522 5.80522 15.6522 7.82609C15.6522 9.84696 14.0209 11.4783 12.0001 11.4783C9.97921 11.4783 8.3479 9.84696 8.3479 7.82609C8.3479 5.80522 9.97921 4.17391 12.0001 4.17391ZM4.17383 17.1662C6.37181 19.4344 9.08677 20.9309 12.2454 20.9309C15.404 20.9309 18.1962 19.4344 19.826 17.1662C19.7881 14.8396 14.7596 13.5652 12.2454 13.5652C9.71849 13.5652 4.21173 14.8396 4.17383 17.1662Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6D202B",
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
