<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="25"
    viewBox="0 0 27 25"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M-2-.5h28v28H-2z" />
      <path
        :fill="primaryColor"
        fill-rule="nonzero"
        d="M11.5 1.5c1.722 0 3.355.379 4.822 1.058a6.002 6.002 0 0 0 6.395 7.9c.185.817.283 1.668.283 2.542 0 6.348-5.152 11.5-11.5 11.5S0 19.348 0 13 5.152 1.5 11.5 1.5zm.235 13c-2.421 0-7.699 1.221-7.735 3.451 2.106 2.174 4.708 3.608 7.735 3.608S17.438 20.125 19 17.95c-.036-2.23-4.855-3.451-7.265-3.451zm-.235-9A3.495 3.495 0 0 0 8 9c0 1.937 1.563 3.5 3.5 3.5S15 10.937 15 9s-1.563-3.5-3.5-3.5z"
      />
      <g>
        <path d="M16.5-.5h12v12h-12z" />
        <path
          :fill="secondaryColor"
          fill-rule="nonzero"
          d="M22 0c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm.6 2h-1.2v2.4H19v1.2h2.4V8h1.2V5.6H25V4.4h-2.4V2z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    primaryColor: {
      type: String,
      default: "#6E6054",
    },
    secondaryColor: {
      type: String,
      default: "#6d202b",
    },
  },
};
</script>
