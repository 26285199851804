<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.42544 1.625H19.1813C21.2817 1.625 22.9437 3.28307 22.9437 5.27881V18.7212C22.9437 20.7181 21.2805 22.375 19.1813 22.375H5.42544C3.3251 22.375 1.66309 20.7169 1.66309 18.7212V5.27881C1.66309 3.2819 3.3263 1.625 5.42544 1.625Z"
      :fill="color"
      :stroke="color"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3628 19.0748H10.9201C10.2577 19.0748 9.71826 18.5492 9.71826 17.9004C9.71826 17.2532 10.2561 16.726 10.9201 16.726L11.4728 16.7276V11.2465H10.9201C10.2577 11.2465 9.71826 10.7209 9.71826 10.0721C9.71826 9.42327 10.2561 8.89771 10.9201 8.89771H12.6746C13.3369 8.89771 13.8764 9.42327 13.8764 10.0721V16.726H14.3628C15.0251 16.726 15.5646 17.2515 15.5646 17.9004C15.5646 18.5492 15.0267 19.0748 14.3628 19.0748Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5499 4.21619C13.4247 4.21619 14.1335 4.90882 14.1335 5.76367C14.1335 6.61852 13.4247 7.31116 12.5499 7.31116C11.6751 7.31116 10.9663 6.61852 10.9663 5.76367C10.968 4.90882 11.6768 4.21619 12.5499 4.21619Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6D202B",
    },
  },
};
</script>
