<template>
  <main class="st-landing-page">
    <st-banner
      v-bind="content.heroTeaser"
      @primary-click="$emit('hero-primary-click')"
      :is-hero-banner="true"
    >
      <slot />
    </st-banner>
    <template v-if="!onlyHeroBanner">
      <section class="st-landing-page__articles">
        <st-article
          :item="article"
          v-for="article in content.articles"
          :key="article.id"
        />
      </section>
      <st-banner v-bind="content.secondaryTeaser" />
    </template>
  </main>
</template>

<script>
import StBanner from "../banner/Banner.vue";
import StArticle from "../article/Article.vue";
export default {
  name: "st-landing-page",
  components: {
    StBanner,
    StArticle,
  },
  props: {
    onlyHeroBanner: Boolean,
    content: {
      type: Object,
      default: () => ({
        heroTeaser: {
          heading: "",
          paragraph: "",
          backgroundImage: "",
          lightTheme: false,
          primaryCTA: {
            link: "",
            label: "",
          },
          secondaryCTA: {
            link: "",
            label: "",
          },
        },
        articles: Array,
        secondaryTeaser: {
          heading: "",
          paragraph: "",
          backgroundImage: "",
          lightTheme: false,
          primaryCTA: {
            link: "",
            label: "",
          },
          secondaryCTA: {
            link: "",
            label: "",
          },
        },
      }),
    },
  },
};
</script>
