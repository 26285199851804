<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.493 13.615H18.1972C18.3474 12.9139 18.4225 12.3756 18.4225 12C18.4225 11.6244 18.3474 11.0861 18.1972 10.385H15.493C15.5681 10.9108 15.6056 11.4491 15.6056 12C15.6056 12.5509 15.5681 13.0892 15.493 13.615ZM14.0657 18.0469C14.6917 17.8466 15.3427 17.471 16.0188 16.9202C16.6948 16.3443 17.2081 15.7684 17.5587 15.1925H15.1925C14.9421 16.1941 14.5665 17.1455 14.0657 18.0469ZM13.8779 13.615C13.9531 13.0892 13.9906 12.5509 13.9906 12C13.9906 11.4491 13.9531 10.9108 13.8779 10.385H10.1221C10.0469 10.9108 10.0094 11.4491 10.0094 12C10.0094 12.5509 10.0469 13.0892 10.1221 13.615H13.8779ZM12 18.385C12.7011 17.3584 13.2144 16.2942 13.5399 15.1925H10.4601C10.7856 16.2942 11.2989 17.3584 12 18.385ZM8.80751 8.80751C9.10798 7.73083 9.48357 6.77934 9.93427 5.95305C9.30829 6.15336 8.64476 6.54147 7.94366 7.11737C7.26761 7.66823 6.76682 8.23161 6.44131 8.80751H8.80751ZM6.44131 15.1925C6.76682 15.7684 7.26761 16.3443 7.94366 16.9202C8.64476 17.471 9.30829 17.8466 9.93427 18.0469C9.43349 17.1455 9.0579 16.1941 8.80751 15.1925H6.44131ZM5.80282 13.615H8.50704C8.43192 13.0892 8.39437 12.5509 8.39437 12C8.39437 11.4491 8.43192 10.9108 8.50704 10.385H5.80282C5.65258 11.0861 5.57746 11.6244 5.57746 12C5.57746 12.3756 5.65258 12.9139 5.80282 13.615ZM12 5.61502C11.2989 6.64163 10.7856 7.70579 10.4601 8.80751H13.5399C13.2144 7.70579 12.7011 6.64163 12 5.61502ZM17.5587 8.80751C17.2081 8.23161 16.6948 7.66823 16.0188 7.11737C15.3427 6.54147 14.6917 6.15336 14.0657 5.95305C14.5164 6.77934 14.892 7.73083 15.1925 8.80751H17.5587ZM6.32864 6.3662C7.9061 4.78873 9.79656 4 12 4C14.2034 4 16.0814 4.78873 17.6338 6.3662C19.2113 7.91862 20 9.79656 20 12C20 14.2034 19.2113 16.0939 17.6338 17.6714C16.0814 19.2238 14.2034 20 12 20C9.79656 20 7.9061 19.2238 6.32864 17.6714C4.77621 16.0939 4 14.2034 4 12C4 9.79656 4.77621 7.91862 6.32864 6.3662Z"
      fill="#514F4F"
    />
  </svg>
</template>
