<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#F6BB0A"
        fill-rule="nonzero"
        d="M25 4c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm.6 2h-1.2v2.4H22v1.2h2.4V12h1.2V9.6H28V8.4h-2.4V6z"
      />
      <path d="M-2-1h31.68v31.68H-2z" opacity=".5" />
      <path
        fill="#6E6054"
        d="M25 15a6.04 6.04 0 0 0 1-.083v8.443A2.64 2.64 0 0 1 23.36 26H4.64A2.64 2.64 0 0 1 2 23.36V12.64A2.64 2.64 0 0 1 4.64 10h14.443A6.002 6.002 0 0 0 25 15zm-9.82-3H12.8L9.3 23h2.26l.682-2.615h3.413L16.338 23h2.341l-3.498-11zm-1.123 2.347c.206 1.007.375 1.9.566 2.832l.148.71.221.797h-2l.237-.797c.214-1.003.458-1.907.657-2.92l.114-.622h.057zM19 9H5a1 1 0 0 1 1-1h13.083A6.04 6.04 0 0 0 19 9zm.342-2H7a1 1 0 0 1 1-1l11.804-.001a5.97 5.97 0 0 0-.462 1z"
      />
    </g>
  </svg>
</template>
