<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="clipSmall">
      <path
        d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM3.96395 14C3.96395 19.5428 8.45724 24.036 14 24.036C19.5428 24.036 24.036 19.5428 24.036 14C24.036 8.45724 19.5428 3.96395 14 3.96395C8.45724 3.96395 3.96395 8.45724 3.96395 14Z"
        fill="white"
      />
    </clipPath>
    <foreignObject
      x="0"
      y="0"
      width="28"
      height="28"
      clip-path="url(#clipSmall)"
    >
      <div class="gradient" xmlns="http://www.w3.org/1999/xhtml"></div>
    </foreignObject>
    <circle
      cx="18"
      cy="2.7"
      r="2.28571"
      transform="rotate(180 16 2.2857)"
      fill="var(--background-color-primary)"
    />
  </svg>
</template>
<style lang="scss" scoped>
@import "../../scss/main.scss";
.gradient {
  width: rem-calc(28);
  height: rem-calc(28);
  border-radius: 50%;
  background: conic-gradient(transparent, var(--background-color-primary));
}
</style>
