<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.76282 17.5H20V5.5H4V18.8851L5.76282 17.5ZM6.45455 19.5L2 23V4.5C2 3.94772 2.44772 3.5 3 3.5H21C21.5523 3.5 22 3.94772 22 4.5V18.5C22 19.0523 21.5523 19.5 21 19.5H6.45455ZM11 14.5H13V16.5H11V14.5ZM8.56731 9.31346C8.88637 7.70919 10.302 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10C15.5 11.933 13.933 13.5 12 13.5H11V11.5H12C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.2723 8.5 10.6656 9.01823 10.5288 9.70577L8.56731 9.31346Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "st-chat-question",
  props: {
    color: {
      type: String,
      default: "#5C5957",
    },
  },
};
</script>
