<template>
  <svg
    width="14"
    height="7"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.3415 6.42381C6.71852 6.75371 7.28148 6.75371 7.6585 6.42381L12.9971 1.75258C13.692 1.14454 13.2619 0 12.3386 0H1.66145C0.738085 0 0.308042 1.14454 1.00294 1.75258L6.3415 6.42381Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#6E6055",
    },
  },
};
</script>
