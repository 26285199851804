<template>
  <div class="st-input" :id="rootId">
    <label class="st-input__label" v-if="labelName" :for="id">{{
      labelName
    }}</label>
    <div :class="classList">
      <input
        :id="id"
        :type="computedType"
        :value="modelValue"
        :placeholder="placeholder"
        class="st-input__container__input-field"
        :disabled="disabled"
        :tabindex="tabindex"
        :required="required"
        :aria-required="required"
        :aria-invalid="invalid"
        :aria-errormessage="ariaErrormessage"
        :aria-autocomplete="ariaAutocomplete"
        :autocomplete="autocomplete"
        :aria-activedescendant="ariaActivedescendant"
        :aria-controls="ariaControls"
        :aria-expanded="ariaExpanded"
        :role="role"
        @blur="onBlur"
        @focus="onFocus"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup.esc="$emit('update:modelValue', '')"
      />
      <div class="st-input__container__component">
        <slot> </slot>
      </div>
    </div>
    <div class="st-input__action" v-if="type === 'password'">
      <st-checkbox :id="`${id}-checkbox`" v-model="showingPassword">
        <span>Visa lösenord</span></st-checkbox
      >
    </div>
  </div>
</template>

<script>
import StCheckbox from "../checkbox/Checkbox.vue";

export default {
  name: "st-input",
  components: {
    StCheckbox,
  },
  data() {
    return {
      localValue: this.value,
      showingPassword: false,
    };
  },
  props: {
    id: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
      validator: function (type) {
        return ["text", "number", "date", "tel", "password", "email"].includes(
          type
        );
      },
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: "0",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    blur: {
      type: Function,
    },
    focus: {
      type: Function,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    rootId: {
      type: String,
    },
    ariaActivedescendant: {
      type: String,
    },
    ariaControls: {
      type: String,
    },
    ariaAutocomplete: {
      type: String,
    },
    labelName: {
      type: String,
    },
    ariaExpanded: {
      type: String,
    },
    role: {
      type: String,
    },
    ariaErrormessage: {
      type: String,
    },
  },

  computed: {
    classList() {
      const input = "st-input__container";
      return [
        input,
        this.invalid && `${input}--is-invalid`,
        this.disabled && `${input}--is-disabled`,
      ];
    },
    computedType() {
      if (this.type === "password") {
        return this.showingPassword ? "text" : "password";
      }
      return this.type;
    },
  },

  methods: {
    onBlur() {
      if (this.blur) this.blur();
    },
    onFocus() {
      if (this.focus) this.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-input {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  &__label {
    color: var(--text-color-primary);
    font-family: var(--font-primary);
    padding-left: var(--xs);
    margin-bottom: rem-calc(2);
    font-size: rem-calc(14);
    font-weight: 600;
    line-height: 1.4;
    text-align: left;
  }
  &__container {
    display: flex;
    align-items: center;
    height: rem-calc(48);
    border: var(--border-width--thin) solid var(--border-color-quaternary);
    border-radius: var(--border-radius);
    color: var(--text-color-primary);
    font-family: var(--font-primary);
    width: 100%;
    transition: 0.2s linear border-color;
    &:focus-within {
      box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
        0 0 0 rem-calc(4) var(--border-color-quaternary);
      border-radius: rem-calc(6);
      outline: none;
    }

    &--is-invalid {
      border-color: var(--danger-color-primary);
      border-width: var(--border-width);
    }

    &--is-disabled {
      background-color: var(--disabled-color-tertiary);
    }

    &__component {
      margin: 0 rem-calc(10);
    }
    &__input-field {
      border-color: transparent;
      background-color: $background-color-secondary;
      color: var(--text-color-primary);
      font-family: var(--font-primary);
      width: 100%;
      padding-left: var(--lg);
      font-size: rem-calc(16);
      line-height: 1.5;
      margin-bottom: 0;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--disabled-color-primary);
      }

      &:disabled {
        background-color: var(--disabled-color-tertiary);
      }
    }
  }
  &__action {
    margin-top: rem-calc(8);
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0 rem-calc(1000) $background-color-secondary inset;
    transition: background-color 1000s ease-in-out 0s;
  }
}
</style>
