<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#6E6054"
      fill-rule="nonzero"
      d="M20 18.586l4.95-4.95a1 1 0 1 1 1.414 1.414L21.414 20l4.95 4.95a1 1 0 1 1-1.414 1.414L20 21.414l-4.95 4.95a1 1 0 1 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 1 1 1.414-1.414l4.95 4.95zM20 38c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18zm0 2C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20z"
    />
  </svg>
</template>
