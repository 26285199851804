<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="26"
    viewBox="0 0 14 26"
  >
    <g fill="none" fill-rule="evenodd">
      <rect width="14" height="26" fill="#6E6054" rx="2.041" />
      <rect width="4" height="1" x="5" y="1.04" fill="#FAFAF7" rx=".5" />
      <rect width="12" height="19.24" x="1" y="2.6" fill="#FAFAF7" rx=".51" />
      <ellipse cx="7" cy="23.92" fill="#FAFAF7" rx="1" ry="1.04" />
    </g>
  </svg>
</template>
