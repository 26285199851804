<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="32"
    viewBox="0 0 44 32"
  >
    <g fill="none" fill-rule="evenodd">
      <rect
        width="43"
        height="31"
        x=".5"
        y=".5"
        fill="none"
        stroke="#514F4F"
        rx="4"
      />
      <path fill="#514F4F" d="M15 17h14v-2H15z" />
      <path fill="#514F4F" d="M23 23V9h-2v14z" />
    </g>
  </svg>
</template>
