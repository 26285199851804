<template>
  <div class="st-icon" :aria-hidden="true">
    <component
      :is="dynamicComponent"
      :aria-label="alt"
      :size="iconSize"
      v-bind="iconProps"
    />
  </div>
</template>

<script>
import * as StIcons from "./st-icons.js";
import * as BtIcons from "./bt-icons.js";
import * as OtIcons from "./ot-icons.js";
const components = { ...StIcons, ...BtIcons, ...OtIcons };

export default {
  name: "st-icon",
  components,
  props: {
    name: {
      type: String,
      required: true,
    },
    iconProps: {
      type: Object,
    },
    alt: {
      type: String,
    },
    theme: {
      type: String,
    },
    size: {
      type: String,
      validator(value) {
        return ["small", "medium", "large"].includes(value);
      },
      default: "medium",
    },
  },
  computed: {
    dynamicComponent() {
      if (!this.theme) {
        return this.name;
      }
      const componentName = this.iconName(this.theme);
      const fallback = this.iconName("st");
      const componentExist = this.componentExist(componentName);
      return componentExist ? componentName : fallback;
    },
    iconSize() {
      const sizes = {
        small: 20,
        medium: 24,
        large: 40,
      };
      return sizes[this.size];
    },
  },

  methods: {
    iconName(theme) {
      return `${theme}-${this.name}`;
    },
    componentExist(componentName) {
      const pascalName = this.convertToPascal(componentName);
      return Object.prototype.hasOwnProperty.call(components, pascalName);
    },
    convertToPascal(string) {
      return string
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
    },
  },
};
</script>

<style lang="scss" scoped>
.st-icon {
  display: inline-flex;
}
</style>
