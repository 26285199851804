<template>
  <section
    class="st-banner"
    :class="{ 'st-banner--is-light': lightTheme }"
    :style="style"
  >
    <component
      :is="`h${isHeroBanner ? '1' : '2'}`"
      class="st-banner__heading"
      :class="{ 'st-banner__heading--is-light': lightTheme }"
    >
      {{ heading }}
    </component>
    <p
      class="st-banner__paragraph"
      :class="{ 'st-banner__paragraph--is-light': lightTheme }"
    >
      {{ paragraph }}
    </p>
    <slot />
    <div
      class="st-banner__actions"
      :class="{ 'st-banner__actions--banner-has-background': backgroundImage }"
      v-if="primaryCTA.link || secondaryCTA.link"
    >
      <st-button
        v-if="primaryCTA.isLogin"
        @click="$emit('primary-click')"
        tabindex="0"
        primary
        >{{ primaryCTA.label }}</st-button
      >
      <st-link
        v-if="primaryCTA.link && !primaryCTA.isLogin"
        :link="primaryCTA.link"
        :is-external="primaryCTA.isExternalLink"
        primary
      >
        {{ primaryCTA.label }}
      </st-link>
      <st-link
        v-if="secondaryCTA.link"
        :link="secondaryCTA.link"
        :is-external="secondaryCTA.isExternalLink"
      >
        {{ secondaryCTA.label }}
      </st-link>
    </div>
  </section>
</template>

<script>
import StLink from "../link/Link.vue";
import StButton from "../button/Button.vue";

export default {
  name: "st-banner",
  components: {
    StLink,
    StButton,
  },
  props: {
    backgroundImage: String,
    heading: String,
    paragraph: String,
    lightTheme: Boolean,
    isHeroBanner: Boolean,
    secondaryCTA: {
      type: Object,
      default: () => ({
        link: "",
        isExternalLink: false,
        label: "",
      }),
    },
    primaryCTA: {
      type: Object,
      default: () => ({
        link: "",
        isExternalLink: false,
        label: "",
      }),
    },
  },
  computed: {
    style() {
      return this.backgroundImage
        ? {
            backgroundImage: `url(${require(`@/assets/${this.backgroundImage}`)})`,
          }
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-banner {
  background-color: var(--background-color-tertiary);
  background-size: cover;
  background-position: 100%;
  background-position-y: 35%;
  color: var(--text-color-primary);
  height: rem-calc(480);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem-calc(35) rem-calc(45);
  @include large {
    justify-content: center;
    padding: 0;
  }
  &--is-light {
    background-color: var(--border-color-quaternary);
  }
  &__heading {
    font-family: var(--font-secondary);
    font-weight: bold;
    letter-spacing: rem-calc(1.06667);
    color: inherit;
    font-size: rem-calc(24);
    line-height: rem-calc(28);
    text-align: center;
    @include large {
      font-size: rem-calc(36);
      line-height: rem-calc(44);
    }
    &--is-light {
      color: var(
        --text-color-tertiary
      ) !important; // Styles from apps overriding if we dont use !important;
    }
  }
  &__paragraph {
    font-family: var(--font-primary);
    color: inherit;
    font-size: rem-calc(18);
    line-height: rem-calc(20);
    margin-top: rem-calc(18);
    text-align: center;
    opacity: 1 !important; // Styles from apps overriding if we dont use !important;
    @include large {
      font-size: rem-calc(24);
      line-height: rem-calc(44);
      margin-top: 0;
    }
    &--is-light {
      color: var(
        --text-color-tertiary
      ) !important; // Styles from apps overriding if we dont use !important;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: rem-calc(18);
    width: 100%;
    &--banner-has-background {
      margin-top: auto;
    }
    @include large {
      margin-top: rem-calc(20);
      flex-direction: row;
    }
    & > *:first-child {
      margin-bottom: rem-calc(8);
      @include large {
        margin-bottom: 0;
        margin-right: rem-calc(8);
      }
    }
  }
}
</style>
