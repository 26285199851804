<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9"
      r="8"
      fill="white"
      stroke="#514F4F"
      stroke-width="1.5"
    />
    <path d="M8 8H10V14H8V8Z" fill="#514F4F" />
    <rect x="8" y="4" width="2" height="2" fill="#514F4F" />
  </svg>
</template>
