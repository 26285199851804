<template>
  <label
    :class="classList"
    :tabindex="disabled ? '-1' : tabindex"
    @keydown.space="handleChange"
    role="checkbox"
    :aria-checked="isChecked"
    :aria-required="required"
  >
    <input
      class="st-checkbox__input"
      type="checkbox"
      :required="required"
      :disabled="disabled"
      @change="handleChange"
      :checked="isChecked"
      tabindex="-1"
    />
    <span class="st-checkbox__checkmark"></span>
    <span class="st-checkbox__text">
      <slot />
      <span v-if="required" class="st-checkbox__required">
        - obligatoriskt
      </span>
    </span>
  </label>
</template>

<script>
export default {
  name: "st-checkbox",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: "0",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isStoryBook() {
      return process.env.STORYBOOK_SELF;
    },
    classList() {
      const checkbox = "st-checkbox";
      return [checkbox, this.disabled && `${checkbox}--is-disabled`];
    },
    isChecked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleChange() {
      this.isChecked = !this.isChecked;
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-checkbox {
  display: flex;
  font-family: var(--font-primary), sans-serif;
  position: relative;
  cursor: pointer;
  font-size: rem-calc(16);
  line-height: rem-calc(21);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  box-sizing: initial;
  align-items: center;

  &__text {
    color: var(--text-color-primary);
    margin-left: rem-calc(8);
  }
  &__required {
    margin-left: rem-calc(3);
    color: var(--text-color-secondary);
    font-style: italic;
    text-wrap: wrap;
  }
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &__checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: rem-calc(20);
    min-width: rem-calc(20);
    width: rem-calc(20);
    background-color: var(--background-color-secondary);
    border-radius: rem-calc(4);
    border: rem-calc(2) solid var(--text-color-secondary);
    outline: rem-calc(2) solid transparent;
    &::after {
      content: "";
      position: relative;
      display: none;
    }
  }

  // Checkbox disabled
  &--is-disabled {
    cursor: default;
  }
  &--is-disabled &__checkmark {
    border-color: var(--border-color-tertiary);
  }
  &--is-disabled &__text {
    color: var(--text-color-quaternary);
  }
  &--is-disabled &__required {
    color: var(--text-color-quaternary);
  }
  &:hover &__input:disabled ~ &__checkmark {
    border-color: var(--border-color-tertiary);
  }
  &:focus &__input:disabled ~ &__checkmark {
    box-shadow: none;
    border-color: var(--border-color-tertiary);
  }
  // Checkbox hover not checked
  &:hover &__input ~ &__checkmark {
    border-color: var(--interactive-color-tertiary);
  }
  // Checkbox checked + hover
  &:hover &__input:checked ~ &__checkmark {
    background-color: var(--interactive-color-tertiary);
    border-color: var(--text-color-secondary);
  }
  // Checkbox focus not checked and checked
  &:focus &__input ~ &__checkmark {
    box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
      0 0 0 rem-calc(4) var(--text-color-secondary);
  }
  & &__input:checked ~ &__checkmark {
    background-color: var(--interactive-color-secondary);
    &::after {
      display: block;
    }
  }
  & &__checkmark {
    &::after {
      left: rem-calc(7);
      top: rem-calc(3);
      width: rem-calc(4);
      height: rem-calc(9);
      border: solid var(--background-color-secondary);
      border-width: 0 rem-calc(3) rem-calc(3) 0;
      transform: rotate(45deg);
    }
  }
}
</style>
